/* eslint-disable no-unused-vars */
import { Box, Container, Grid, Typography, Button, Stack, Card, CardContent, CardMedia, Divider, DialogActions, useMediaQuery, Radio, Chip, Checkbox, Alert, Tooltip } from "@mui/material";
import "react-tabs/style/react-tabs.css";
import React, { useRef } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Register from "../Register/Register";
import { useEffect, useState } from "react";
import axios from "axios";
import { base_Url } from "../BaseUrl/ApiUrl";
import { Toaster, toast } from "react-hot-toast";
import _ from 'lodash'
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import groupImage from '../../assets/combo/group.jpg'
import groupImageForMobile from '../../assets/combo/Union.png'


import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import UpsellingDialog from "./UpsellingDialog";
import { googleAnalyticsFunction } from "../googleanalytics/googleanalytics";
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import addIconf from '../../assets/images/icons8-add-67.png'
import NewUserRegister from "../Register/NewUserRegister";
import CustomScrollbar from "../utils/CustomScrollbar";
import { RiErrorWarningLine } from "react-icons/ri";
import MainCard from "../../components/MainCard";

import { useLocation, useParams } from 'react-router-dom'




const styles = {
  tabGridItems: {
    // display: "grid",
    // gridTemplateColumns: "repeat(2, 1fr)",
    // alignItems: "baseline",
    // "@media (max-width: 600px)": {
    //   gridTemplateColumns: "unset",
    // },
    marginBottom: '30px'
  },
  dotIcon: {
    color: "var(--primary)",
    fontSize: "12px",
  },
  tabAmount: {
    color: "#000",
    marginRight: "20px",
  },
  tabGreenBox: {
    background: "var(--green)",
    color: "#fff",
    fontSize: "12px",
    padding: "0px 0px",
    borderRadius: "5px",
  },
  behindTheWheelYellowBox: {
    background: "#FFED97",
    fontSize: "13px !important",
    width: "fit-content",
    marginLeft: "auto",
    borderRadius: "4px",
    padding: "0px 10px !important",
  },
  behindTheWheelBox: {
    background: "#fff",
    border: "1px solid #DADADA",
    borderRadius: "10px",
  },
  packagesBox: {
    boxShadow: "3px 3px 10px 5px #00000012",
    borderTop: "2px solid var(--primary)",
    padding: "20px",
  },
  packagesBoxAttributes: {
    mt: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  packagesBoxAttributeItems: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  packagesBoxButton: {
    mt: "20px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "4px",
  },
  tabMainBox: {
    background: "#F4F8FC",
    padding: "30px",
    paddingLeft: "30px !important",
    "@media (max-width: 600px)": {
      width: "100%",
      padding: '0 0 0 10px !important',
      margin: '0 0 0 20px !important'
    },
  },
  chooseCombo: {
    background: "#4ade80",
    padding: "20px !important",
    "@media (max-width: 600px)": {
      width: "100%",
      marginTop: "30px",
      marginLeft: '20px !important'
    },
  },
  buynowBlueButton: {
    border: "1px solid var(--primary)",
    color: "#fff",
    padding: "10px 16px",
    background: "var(--primary)",
    "&:hover": { color: "var(--primary)", background: "#fff" },
  },
  // scroolbarLocation: {
  //   height: "386px",
  //   overflowY: " scroll",
  //   scrollbarWidth: "1px",
  //   msOverflowStyle: "none",
  //   // "&::-webkit-scrollbar": {
  //   //   display: "none",
  //   // },
  //   "@media (max-width: 900px)": {
  //     height: "490px",
  //   },
  // },
  scroolbarLocation: {
    height: "386px",
    overflowY: "scroll",  // Always show the scrollbar
    "@media (max-width: 900px)": {
      height: "300px",
    },
    // Remove scrollbar styling to avoid potential conflicts
    "::-webkit-scrollbar": {
      display: "block",  // Ensures scrollbar is always displayed on WebKit browsers
    },
  },

};





function DlPackages() {

  const location = useLocation()
  const { number } = location.state || {}


  console.log('number >>>', number)
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

  const navigate = useNavigate()
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedBehindCourse, setSelectedBehindCourse] = useState(null);

  const [selectedBTWHour, setSelectedBTWHour] = useState(null)

  console.log('selectedBehindCourse >>>', selectedBehindCourse)


  console.log('selectedBTWHour >>>', selectedBTWHour)

  const handleTabClick = (item) => {
    console.log('item >>>>?????????', item)


    setSelectedBehindCourse((prevSelected) =>
      prevSelected === item.packageid ? null : item.packageid
    );

    setSelectedBTWHour((prevSelected) =>
      prevSelected === item.duration ? null : item.duration
    );


  };




  const [BehindTheWheel, setBehindTheWheel] = useState([])
  const [Dui, setDui] = useState([])
  const [defensive, setDefensive] = useState([])
  const [driversEducation, setDriversEducation] = useState([])
  const [roadTesting, setRoadTesting] = useState([])
  const [BtwRoadtest, setBtwRoadTest] = useState([])

  console.log('roadTesting >>>', roadTesting)

  useEffect(() => {
    if (BehindTheWheel.length > 0) {
      setBtw(BehindTheWheel[0])
    }
  }, [BehindTheWheel])


  // useEffect(() => {
  //   let price;
  //   if (!_.isNull(selectedBehindCourse)) {
  //     price = roadTesting.find((item) => {
  //       return item.packageamount == 125
  //     })
  //   } else {
  //     price = roadTesting.find((item) => {
  //       return item.packageamount !== 125
  //     })
  //   }
  //   console.log('price >>', price)
  // }, [selectedBehindCourse])





  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: ''
      })
      if (response.data.message === "success") {
        console.log('response', response?.data?.response)
        const course1 = response?.data?.response[0]?.courseDetails
        setBehindTheWheel(course1)
        setSelectedBehindCourse(course1[0]?.packageid)
        setSelectedBTWHour(course1[0]?.duration)

        const course2 = response?.data?.response[1]?.courseDetails
        setDui(course2)

        const course3 = response?.data?.response[2]?.courseDetails
        setDefensive(course3)

        const course4 = response?.data?.response[3]?.courseDetails
        setDriversEducation(course4)

        const course5 = response?.data?.response[4]?.courseDetails



        // let lowerPackage = [];
        // let higherPackage = [];

        // if (course5?.length === 2) {
        //   if (course5[0].packageamount < course5[1].packageamount) {
        //     lowerPackage.push(course5[0]);
        //     higherPackage.push(course5[1]);
        //   } else {
        //     lowerPackage.push(course5[1]);
        //     higherPackage.push(course5[0]);
        //   }
        // }
        // setBtwRoadTest(lowerPackage)
        setRoadTesting(course5)
        // console.log("kavin", lowerPackage);
        // console.log("Higher Package:", higherPackage);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [])



  const [productTypeCount, setProductTypeCount] = useState({});
  const handleSelect = (item) => {
    const isSelected = selectedCourses.includes(item.packageid);
    const currentProductTypeCount = { ...productTypeCount };

    if (isSelected) {
      // If item is already selected, we need to remove it
      setSelectedCourses(selectedCourses.filter(packageid => packageid !== item.packageid));
      // Decrease the count of the product type
      currentProductTypeCount[item.producttype] = (currentProductTypeCount[item.producttype] || 1) - 1;

    } else {

      if (item.producttype === 5 && currentProductTypeCount[5] >= 1) {
        toast.error('Road test course already selected.')
        return;
      }


      currentProductTypeCount[item.producttype] = (currentProductTypeCount[item.producttype] || 0) + 1;
      setSelectedCourses([...selectedCourses, item.packageid]);
    }

    // Update the productTypeCount state
    setProductTypeCount(currentProductTypeCount);
  };


  const isSelected = (item) => selectedCourses.includes(item.packageid);



  const renderCourses = (courses) => (



    courses?.map((item, i) => (
      <Grid
        key={i}
        sx={{
          ...styles.behindTheWheelBox,
          backgroundColor: isSelected(item) ? '#1976d2' : 'white',
          color: isSelected(item) ? 'white' : 'black',
          cursor: 'pointer'
        }}
        onClick={() => handleSelect(item)}
      >
        {console.log('courses item>>>', item)}
        <Grid sx={{ p: '20px' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            {
              item.producttype == 5 ?
                <Typography
                  variant="h6"
                  fontWeight="500 !important"
                  sx={{ color: isSelected(item) ? 'white' : 'black', }}
                >
                  {`${item?.packagename}`}
                </Typography>
                :
                <Typography
                  variant="h6"
                  fontWeight="500 !important"
                  sx={{ color: isSelected(item) ? 'white' : 'black', }}
                >
                  {`${item?.duration} hours of ${item?.packagename}`}
                </Typography>
            }
            <Typography sx={{ color: isSelected(item) ? 'white' : 'black', }}>

              {item.producttype == 4 &&
                selectedBTWHour >= 6 ?
                <b>${item?.combopackageamount1}</b> :
                <b>${item.packageamount}</b>
              }


              {/* {courses.productid == 3 &&
                selectedBTWHour < 12 ?
                <b>{item?.combopackageamount1}</b> :
                selectedBTWHour >= 12 ?
                  <b>${item.combopackageamount2}</b> :
                  <b>${item.packageamount}</b>
              } */}




            </Typography>
          </Stack>
        </Grid>
        {
          item?.producttype === 3 &&
          <>

            <Divider sx={{ mb: 1 }} />
            <div style={{ paddingLeft: '10px' }}>


              <Typography variant="caption">
                This defensive class purchased as a Combo may help with Insurance reduction only;
                Teens under the age of 18 should take 30-hr driver’s education class instead to meet Joshua law requirements.
              </Typography>
            </div>

          </>

        }

      </Grid>
    ))
  );







  // checing in api wheather the combo exist or not


  const isStudentExist = JSON.parse(localStorage.getItem('Student'))

  console.log('sky', isStudentExist)

  const [finalData, setFinalData] = useState({})
  const fetchcheckPackageisExist = async (array) => {
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: array,
        studentid: isStudentExist?.customerid || 0
      })
      if (response.data.message === 'success') {
        console.log('response', response?.data?.response[0])
        setFinalData(response?.data?.response)
      } else {
        setFinalData({})
        toast.error('course already purchased')
      }
    } catch (error) {
      console.log(error)
    }
  }




  useEffect(() => {
    const combinedArray = [...[selectedBehindCourse], ...selectedCourses];
    let filteredArray = combinedArray.filter(element => element !== null);
    if (filteredArray.length > 0) {
      console.log('filteredArray    >>>>>>>>>>', filteredArray)
      fetchcheckPackageisExist(filteredArray)
    }
  }, [selectedCourses, selectedBehindCourse])


  // console.log('selectedCourses >>>', selectedCourses);






  const [registerStatus, setRegisterStatus] = useState(false)


  const handleAddToCart = async () => {
    const ComboproductArray = finalData.packages?.map((item) => {
      return {
        "productid": item.productid,
        "productname": item.productname,
        "packageid": item.packageid,
        "packagename": item.packagename
      }
    })

    const singleproductArray = finalData.packages?.map((item) => {
      return {
        "productid": item.productid,
        "productname": item.productname,
        "packageid": item.packageid,
        "packagename": item.packagename,
        "totalamount": item?.packageamount
      }
    })

    console.log('selected CCCC>>>>>', finalData)
    let requestedData;
    const tempid = localStorage.getItem('tempId')
    if (finalData?.comboid > 0) {
      requestedData = {
        "tempcustomerid": tempid ? tempid : "",
        "customerid": 0,
        "combo": [
          {
            "comboid": finalData?.comboid,
            "products": ComboproductArray,
            "totalamount": finalData.discountprice
          }
        ],

        "noncombo": []
      }
    } else {
      requestedData = {
        "tempcustomerid": tempid ? tempid : "",
        "customerid": 0,
        "combo": [],
        "noncombo": singleproductArray
      }
    }
    try {
      const response = await axios.post(`${base_Url}/addtocart`, requestedData)
      if (response.data.status) {
        toast.success('Item added ')
        if (!tempid) {
          localStorage.setItem('tempId', response?.data?.response)
        }
      }

    } catch (error) {
      toast.error('unable to add item ')
      console.log(error)
    }
  }


  const handleKnowMoreclick = (type) => {
    if (type == 3) {
      return navigate('/defensive-driving-course-remote', {
        state: { number: 2 }
      })
    }

    const routes = {
      3: '/defensive-driving-course-remote',
      4: '/drivers-education',
      5: '/road-test-services'
    };
    navigate(routes[type]);
  }



  const my = () => {
    toast.custom((t) => (
      <div>
        This is a custom component <button onClick={() => toast.dismiss(t)}>close</button>
      </div>
    ));
  }


  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [open, setOpen] = useState(false);


  const handleClose = () => {
    // if (dontShowAgain) {
    sessionStorage.setItem("showDialog", "false");
    // } else {
    //   sessionStorage.setItem("showDialog", "true");
    // }
    setOpen(false);
    // window.scrollTo(0, 900)
  };

  const handleCheckboxChange = (event) => {
    // event.preventDefault()
    setDontShowAgain(event.target.checked);
  };


  const AnimatedDialog = styled(Dialog)({
    "& .MuiPaper-root": {
      animation: `$slideIn 5s`,
      "@keyframes slideIn": {
        "0%": {
          transform: "translateX(-100%)",
        },
        "100%": {
          transform: "translateX(0)",
        },
      },
    },
  });



  const [activeSection, setActiveSection] = useState(null); // State to track the active section

  // 📢 changes made by ganesh 📢

  // useEffect(() => {
  //   // Check the stored value when the component mounts
  //   const showDialog = sessionStorage.getItem("showDialog");

  //   if (showDialog === null) {
  //     // Set default value if not set yet
  //     sessionStorage.setItem("showDialog", "true");

  //   } else if (showDialog === "true") {
  //     if (activeSection) {
  //       if (selectedBTWHour == 2 || selectedBTWHour == 4 || selectedBTWHour == 8 || selectedBTWHour == 10) {
  //         setOpen(true);
  //       }
  //     }
  //   }
  // }, [selectedBTWHour, activeSection]);

  useEffect(() => {
    if (number !== 10) {
      setOpen(true)
    }

  }, [])

  // 📢 changes made by ganesh 📢



  // test code 

  useEffect(() => {
    // Function to handle scrolling and detect section in view
    const handleScroll = () => {
      const section = document.getElementById('section-1');
      const rect = section.getBoundingClientRect();

      // Check if section is in the viewport
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setActiveSection('section-1'); // Update state when section is fully in view
      } else {
        setActiveSection(null); // Reset state when section is out of view
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [newUser, setNewUser] = useState(false)


  const handleCheckOutOpen = (data) => {

    const isLoggedIn = localStorage.getItem('Student')

    try {
      if (data) {
        console.log(`course data for google analytics - `, data);
        const courseData = data?.packages?.map((val) => {
          return {
            productName: val?.packagename,
            productPrice: val?.packageamount
          }
        })
        googleAnalyticsFunction(data || {}, courseData || [])
        console.log(`value - `, data);
        // if (isLoggedIn == null) {
        setNewUser(true)
        // }
        // else {
        //   setRegisterStatus(true)
        // }
      }
      else {
        // if (isLoggedIn == null) {
        setNewUser(true)
        // }
        // else {
        // setRegisterStatus(true)
        // }
      }
    } catch (error) {
      console.log(`error in handleCheckOutOpen - `, error);
    }
  }


  // sticky buy now button for small devices only

  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowWidth = window.innerWidth; // Get the window width

    // Check if the window width is 600px or less and scroll position is between 500px and 900px
    if (windowWidth <= 600 && scrollY >= 1200 && scrollY <= 2650) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // sticky buy now button for small devices only


  const [btw, setBtw] = useState({})

  // const containerRef = useRef(null);

  // useEffect(() => {
  //   // Initialize PerfectScrollbar
  //   const ps = new PerfectScrollbar(containerRef.current, {
  //     handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  //     wheelSpeed: 1,
  //     wheelPropagation: false,
  //     swipeEasing: true,
  //     minScrollbarLength: 20, // Set minimum length for the scrollbar
  //     maxScrollbarLength: 100, // Set maximum length for the scrollbar
  //     scrollingThreshold: 1000,
  //     useBothWheelAxes: false,
  //     suppressScrollX: true, // Disable horizontal scrolling
  //     suppressScrollY: false,
  //     scrollXMarginOffset: 0,
  //     scrollYMarginOffset: 0,
  //   });

  //   return () => {
  //     ps.destroy(); // Clean up on component unmount
  //   };
  // }, []);



  console.log(`epic btw value - `, btw);

  const isXs = useMediaQuery('(max-width:600px)')


  const priceHelper = (data1, data2) => {
    return data1 == data2 ? true : false
  }


  // scrolbar
  const myScrollbar = {
    width: 400,
    height: 400,
  };




  return (
    <>


      {/* <div>
        <div style={{ height: '100vh' }}>Scroll Down</div>

        <div
          id="section-1"
          style={{
            height: '500px',
            backgroundColor: activeSection === 'section-1' ? 'lightblue' : 'lightgray',
          }}
        >
          Section 1 (Scroll into view)
        </div>

        <div style={{ height: '100vh' }}>More Content</div>
      </div> */}


      <Toaster />

      {/* web view */}
      <Box sx={{ width: '80%', margin: 'auto', display: { xs: 'none', sm: 'none', md: 'block' } }}>

        <Typography variant="h3" sx={{ textAlign: 'center', margin: '10px 0' }}>Behind-the-Wheel</Typography>


        <Typography variant="h5"



          sx={{
            textAlign: 'center',
            margin: '20px auto', // Center horizontally
            background: 'linear-gradient(45deg, #ba68c8, #f06292)',
            color: 'white !important', // Text color for readability
            fontSize: '25px',
            padding: '15px',
            borderRadius: '20px',
            maxWidth: 'fit-content', // Shrink to fit content
            width: 'fit-content', // Adjust to content
            animation: 'offerText 2s infinite ', // Add animation
            boxShadow: '0px 0px 15px 3px #8e24aa',

            '@keyframes offerText': {
              '0%': {
                // boxShadow: '0px 0px 10px 2px #ff6f61',
              },
              '70%': {
                boxShadow: '3px 3px 10px 1px #8e24aa'
              },
            },
          }}
        >
          Get 15% off this Spring season – Offer valid from Apr 01 to Apr 30, 2025!
        </Typography>




        <Divider />

        <Grid container sx={{ display: 'flex', background: '#f4f8fc', marginTop: '10px' }} direction={'row'} alignItems={'center'}> {/* BTW */}

          <Grid item sm={12} md={4} lg={4} sx={{ padding: '30px' }} alignSelf={'flex-start'}> {/* First - left */}

            <Stack spacing={2}>

              <Typography variant="h3" sx={{ width: '300px !important' }}>Gear Up for Your Driver's License!</Typography>
              <Typography sx={{ color: 'grey !important' }}>Select Hours - Pick Your Time Slots</Typography>

            </Stack>

          </Grid>

          <Grid item sm={12} md={8} lg={8} sx={{ padding: '30px' }}> {/* First - right */}

            <div style={{ height: '190px', width: '100%', border: '1px solid #d4d4d8' }}>
              <CustomScrollbar>


                <Grid container sx={{ paddingRight: '10px' }} >
                  {BehindTheWheel.map((items, index) => (
                    <Grid item xs={12} sm={4} md={4} key={index} sx={{ border: '1px solid #d4d4d8' }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          cursor: 'pointer',
                          p: 1,
                          border: selectedBehindCourse === items.packageid ? '1px solid #0063D3' : '1px solid transparent',
                          borderRadius: '3px',
                          background: selectedBehindCourse === items.packageid ? '#E3F2FD' : 'white',
                          transition: 'background 0.3s, transform 0.3s',
                          '&:hover': {
                            background: '#E0F7FA',
                            transform: 'scale(1.02)',
                          },
                        }}
                      >
                        <Radio
                          checked={selectedBehindCourse === items.packageid}  // Ensure Radio is checked based on the selectedBehindCourse state
                          onChange={() => handleTabClick(items)}  // Handle state change on Radio click
                          value={items.packageid}
                          name="behindTheWheel"
                          sx={{
                            color: selectedBehindCourse === items.packageid ? '#0063D3' : 'default',
                            '&.Mui-checked': {
                              color: '#0063D3',
                            },
                          }}
                        />
                        <Typography variant="body1" sx={{ flex: 1 }} onClick={() => handleTabClick(items)}
                        >
                          {items.duration} Hours
                        </Typography>


                        <Stack>
                          {items.discount > 0 ?

                            <>

                              <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }} onClick={() => handleTabClick(items)}
                              >
                                ${items.discountprice}
                              </Typography>

                              <Typography sx={{ fontWeight: 'bold', color: 'gray', textDecoration: "line-through" }} onClick={() => handleTabClick(items)}
                              >
                                ${items.packageamount}
                              </Typography>

                            </>
                            :

                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }} onClick={() => handleTabClick(items)}
                            >
                              ${items.packageamount}
                            </Typography>


                          }



                        </Stack>
                      </Stack>
                    </Grid>
                  ))}

                </Grid>


              </CustomScrollbar>
            </div>

          </Grid>

        </Grid>


        {/* combo section */}
        <Grid
          container
          sx={{
            background: `url(${groupImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >

          <Grid
            item sm={12} md={3} lg={2} sx={{ padding: '20px', }}
          >

            <Typography
              variant="h6"
              sx={{ fontSize: '30px !important', fontWeight: 900 }}
            >
              COMBO OFFERS FOR YOU
            </Typography>

          </Grid>

          <Grid item sm={12} md={9} lg={10} sx={{ padding: '30px 70px' }}>

            <Stack>

              <Stack direction={'row'} spacing={2} alignItems={'center'}>

                <Chip label={'BUNDLE UP & SAVE'} sx={{ background: '#f6cb00', color: 'black', fontSize: '25px', fontWeight: 500, padding: '20px' }} />

                <Typography sx={{ color: 'white !important' }}>Click Below Course And Buy as a Package</Typography>

              </Stack>




            </Stack>

            <Stack direction={'row'} spacing={2} sx={{ padding: '10px 0' }}>

              {/* drivers education */}
              <Tooltip title={<Stack>

                <Typography variant="caption">
                  Joshua's Law Mandates that 16 and 17 Year olds must complete.
                </Typography>

                <Typography variant="caption">
                  1. At least 6 hours of Driving lessons and
                </Typography>

                <Typography variant="caption">
                  2. 30-hour Driver's education course.
                </Typography>

              </Stack>} >
                <Grid container spacing={2} sx={{ display: "flex", gap: "16px", flexDirection: "row", }}>
                  {driversEducation?.map((item, i) => (
                    <Grid
                      key={i}
                      item
                      sx={{
                        backgroundColor: isSelected(item) ? 'white' : 'transparent', // Green background for unselected
                        color: isSelected(item) ? 'white' : 'black',
                        borderRadius: '10px',
                        border: isSelected(item) ? '2px solid white' : '2px solid white',
                        cursor: 'pointer',
                        padding: '16px',
                        minWidth: '250px', // Set a minimum width to keep the cards uniform
                      }}
                      onClick={() => handleSelect(item)}
                    >
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Checkbox
                          checked={isSelected(item)}
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#26b170',
                            },
                          }}
                        />
                        <Typography
                          variant="h6"
                          fontWeight="500"
                          sx={{ color: isSelected(item) ? 'black' : 'white !important', marginLeft: '8px' }}
                        >
                          {`${item.packagename}`}
                        </Typography>
                        <Stack direction="column" alignItems="flex-end">
                          {item.producttype == 4 && selectedBTWHour >= 6 ? (
                            <>
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: '20px',
                                  color: isSelected(item) ? 'black' : 'white !important',
                                  fontWeight: 'bold',
                                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                }}
                              >
                                ${item.combopackageamount1}
                              </Typography>
                              <Typography
                                component="span"
                                sx={{ textDecoration: 'line-through', color: isSelected(item) ? 'black' : 'white' }}
                              >
                                ${item.packageamount}
                              </Typography>
                            </>
                          ) : (
                            <Typography component="span" sx={{ color: isSelected(item) ? 'black' : 'white !important' }}>
                              <b>${item.packageamount}</b>
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Tooltip>
              {/* drivers education */}

              {/* Road Test */}
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  gap: "16px",
                  flexDirection: "row",
                }}
              >
                {roadTesting?.map((item, i) => (
                  <Grid
                    key={i}
                    item
                    sx={{
                      backgroundColor: isSelected(item) ? 'white' : 'transparent',
                      color: isSelected(item) ? 'white' : 'black',
                      borderRadius: '10px',
                      border: isSelected(item) ? '2px solid white' : '2px solid white',
                      cursor: 'pointer',
                      padding: '16px',
                      width: '100%',
                    }}
                    onClick={() => handleSelect(item)}
                  >
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Checkbox
                        checked={isSelected(item)}
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: '#26b170',
                          },
                        }}
                      />
                      <Typography
                        variant="h6"
                        fontWeight="500"
                        sx={{ color: isSelected(item) ? 'black' : 'white !important', marginLeft: '8px' }}
                      >
                        {item.producttype == 5 ? item.packagename : `${item.duration} hours of ${item.packagename}`}
                      </Typography>
                      <Stack direction="column" alignItems="flex-end">
                        {selectedBTWHour ? (
                          <>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: '20px',
                                color: isSelected(item) ? 'black' : 'white !important',
                                fontWeight: 'bold',
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                              }}
                            >
                              ${item.combopackageamount1}
                            </Typography>
                            <Typography
                              component="span"
                              sx={{ textDecoration: 'line-through', color: isSelected(item) ? 'black' : 'white !important' }}
                            >
                              ${item.packageamount}
                            </Typography>
                          </>
                        ) : (
                          <Typography component="span" sx={{ color: isSelected(item) ? 'black' : 'white !important' }}>
                            <b>${item.packageamount}</b>
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    {item?.producttype === 3 && (
                      <>
                        <Divider sx={{ mb: 1, borderColor: 'white' }} />
                        <div style={{ paddingLeft: '10px' }}>
                          <Typography variant="caption" sx={{ color: isSelected(item) ? 'white' : 'black' }}>
                            This defensive class purchased as a Package may help with Insurance reduction only;
                            Teens under the age of 18 should take a 30-hr Driver’s Education class instead to meet Joshua law requirements.
                          </Typography>
                        </div>
                      </>
                    )}
                  </Grid>
                ))}
              </Grid>
              {/* Road Test */}

              {/* defensive */}
              <Tooltip title={<Stack>

                <Typography variant="caption">
                  1 .  This defensive class purchased as a Package  may help with Insurance reduction only.
                </Typography>

                <Typography variant="caption">
                  2 . Teens under the age of 18 should take a 30-hr Driver’s Education class instead to meet Joshua law requirements.
                </Typography>

              </Stack>}>
                <Grid
                  container
                  spacing={2}

                >
                  {defensive?.map((item, i) => (
                    <Grid
                      key={i}
                      item
                      sx={{
                        backgroundColor: isSelected(item) ? 'white' : 'transparent',
                        color: isSelected(item) ? 'black' : 'white !important',
                        borderRadius: '10px',
                        border: isSelected(item) ? '2px solid white' : '2px solid white',
                        cursor: 'pointer',
                        padding: '16px',
                        width: '100%',
                      }}
                      onClick={() => handleSelect(item)}
                    >
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Checkbox
                          checked={isSelected(item)}
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#26b170',
                            },
                          }}
                        />
                        <Typography
                          variant="h6"
                          fontWeight="500"
                          sx={{ color: isSelected(item) ? 'black' : 'white !important', marginLeft: '8px' }}
                        >
                          {item.producttype == 5 ? item.packagename : `${item.packagename}`}
                        </Typography>
                        <Stack direction="column" alignItems="flex-end">
                          {!_.isNil(selectedBTWHour) && selectedBTWHour < 12 ? (
                            <>
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: '20px',
                                  color: isSelected(item) ? 'black' : 'white !important',
                                  fontWeight: 'bold',
                                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                }}
                              >
                                ${item.combopackageamount1}
                              </Typography>
                              <Typography
                                component="span"
                                sx={{ textDecoration: 'line-through', color: isSelected(item) ? 'black' : 'white !important' }}
                              >
                                ${item.packageamount}
                              </Typography>
                            </>
                          ) : selectedBTWHour >= 12 ? (
                            <>
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: '20px',
                                  color: isSelected(item) ? 'black' : 'white !important',
                                  fontWeight: 'bold',
                                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                }}
                              >
                                ${item.combopackageamount2}
                              </Typography>
                              <Typography
                                component="span"
                                sx={{ textDecoration: 'line-through', color: isSelected(item) ? 'black' : 'white !important' }}
                              >
                                ${item.packageamount}
                              </Typography>
                            </>
                          ) : (
                            <Typography component="span" sx={{ color: isSelected(item) ? 'black' : 'white !important' }}>
                              <b>${item.packageamount}</b>
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                      {/* {item?.producttype === 3 && (
                      <>
                        <Divider sx={{ mb: 1, borderColor: 'white' }} />
                        <div style={{ paddingLeft: '10px' }}>
                          <Stack spacing={1}>
                            <Typography variant="caption" sx={{ color: isSelected(item) ? 'white' : 'black' }}>
                              <FiberManualRecordIcon sx={{ fontSize: "12px" }} /> &nbsp;
                              This defensive class purchased as a Package may help with Insurance reduction only.
                            </Typography>
                            <Typography variant="caption" sx={{ color: isSelected(item) ? 'white' : 'black' }}>
                              <FiberManualRecordIcon sx={{ fontSize: "12px" }} /> &nbsp;
                              Teens under the age of 18 should take a <br /> 30-hr Driver’s Education class instead to meet Joshua law requirements.
                            </Typography>
                          </Stack>
                        </div>
                      </>
                    )} */}
                    </Grid>
                  ))}
                </Grid>
              </Tooltip>
              {/* defensive */}

            </Stack>




          </Grid>

        </Grid>
        {/* combo section */}

        <Box sx={{ height: '5px', background: '#0063d3', marginTop: '0 !important' }}></Box>



        {/* final value */}

        {
          !_.isEmpty(finalData) ? <MainCard>

            {finalData?.packages?.map((item, index) => (
              <React.Fragment key={index}>
                <Typography variant="h5" sx={{ color: 'black !important' }}>
                  {item.packagename}
                </Typography>
              </React.Fragment>
            ))}

            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ margin: { md: "20px 0" } }}>

              <Stack
                sx={{
                  position: isSticky ? 'fixed' : 'relative', // Fixed position only when sticky
                  bottom: isSticky ? 30 : 'auto',
                  left: isSticky ? 0 : 'auto',
                  right: isSticky ? 0 : 'auto',
                  zIndex: isSticky ? 1000 : 'auto',
                  backgroundColor: 'white', // Add background color for visibility
                  transition: 'all 0.3s ease-in-out', // Smooth transition for changes
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >

                {/* {
                  priceHelper(Math.round(finalData?.discountprice), finalData?.finalcost) ?
                    <Typography sx={{ textAlign: 'center !important', width: '100%', borderRadius: { xs: '10px', md: '' }, boxShadow: { xs: isSticky ? '0px 4px 10px rgba(0, 0, 0, 0.7)' : 'none', md: 'none' }, background: { xs: isSticky ? '#fdba74' : 'white', md: 'white', lg: 'white' } }}>
                      {
                        finalData?.comboid == 0 ?
                          <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                            ${finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)}

                          </span> :
                          <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>
                            ${finalData?.discountprice}
                          </span>
                      }
                      &nbsp;&nbsp;

                    </Typography> :
                    <Typography
                      sx={{
                        textAlign: 'center !important', width: '100%', borderRadius: { xs: '10px', md: '' },
                        boxShadow: { xs: isSticky ? '0px 4px 10px rgba(0, 0, 0, 0.7)' : 'none', md: 'none' }, background: { xs: isSticky ? '#fdba74' : 'white', md: 'white', lg: 'white' }
                      }}>

                      <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        {
                          finalData?.comboid == 0 ?
                            <span style={{ fontWeight: "700", fontSize: "30px", color: "black", marginRight: '20px' }}>

                            </span> :
                            <span
                              style={{
                                textDecoration: "line-through",
                                fontSize: "20px",
                                color: 'gray',
                                marginRight: '20px'
                              }}
                            >
                              ${finalData?.finalcost}
                            </span>
                        }
                        {
                          finalData?.comboid == 0 ?
                            <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                              ${finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)}

                            </span> :
                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                              <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>
                                ${finalData?.discountprice}
                              </span>
                              <Alert icon={false} severity="warning">
                                save ${Number(finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)) - Number(finalData?.discountprice)}
                              </Alert>
                            </Stack>
                        }
                      </Stack>

                      &nbsp;&nbsp;


                    </Typography>
                } */}



                {/* <p>new code</p>  */}








                {
                  // single course
                  finalData?.comboid === 0 ? (
                    <Typography
                      sx={{
                        fontWeight: "700 !important",
                        fontSize: "30px !important",
                        color: "black !important",
                      }}
                    >


                      ${finalData?.discountprice}
                    </Typography>

                  ) : (



                    // combo course


                    // no discount found  display discountprice only
                    // there is no discount 
                    finalData?.finalcost === finalData?.discountprice ? (
                      <Typography
                        sx={{ fontWeight: "700 !important", fontSize: "30px !important", color: "black !important" }}
                      >
                        ${finalData?.discountprice}
                      </Typography>
                    ) : (

                      // display amount with discount and origanl and saving price
                      <Stack direction={'row'} spacing={2} alignItems={'center'}>

                        <Typography
                          sx={{ fontWeight: "500 !important", fontSize: "24px !important", color: "gray !important", textDecoration: "line-through", marginRight: '10px !important' }}
                        >

                          ${finalData?.finalcost}
                        </Typography>


                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                          <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>
                            ${finalData?.discountprice}
                          </span>
                          <Alert icon={false} severity="warning">
                            Save ${(
                              Number(finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)) -
                              Number(finalData?.discountprice)
                            ).toFixed(2)}
                          </Alert>
                        </Stack>

                      </Stack>
                    )
                  )
                }




              </Stack>

              <button onClick={() => handleCheckOutOpen(finalData)} style={{ padding: '10px 40px', fontSize: '20px', fontWeight: 'bold', background: '#0063d3', color: 'white', borderColor: 'transparent', cursor: 'pointer' }}>Buy Now</button>

            </Stack>


            <Stack direction={'row'} alignItems={'center'} spacing={2}>

              <Alert severity="error" color="warning" sx={{ background: 'white' }}>

                <Stack direction={'row'}>

                  <Typography sx={{ fontWeight: 'bold !important' }}>Please note : </Typography>
                  <Typography> Teens Age 16 & 17 are required to take the 30-hour Driver's Education class in order to take their Driver's License.</Typography>

                </Stack>

              </Alert>

            </Stack>


          </MainCard> : <Grid item xs={12} md={4}>
            <Card>

              <CardMedia
                component="img"
                image={'https://webnox.blr1.digitaloceanspaces.com/studentportal/Course-not-Found.jpg'}
                alt="Behind the Wheel"
                height={200}
                sx={{ objectFit: 'cover' }}
              />


              <CardContent>


                <React.Fragment >
                  <Typography sx={{ color: 'black !important', fontSize: '20px', fontWeight: 400 }}>
                    Course already purchased
                  </Typography>
                  <Typography sx={{ color: 'black !important', fontSize: '20px', fontWeight: 400 }}>
                    please choose another combo...
                  </Typography>

                </React.Fragment>



              </CardContent>
            </Card>

          </Grid>
        }

        {/* final value */}



      </Box >
      {/* web view */}



      {/* mobile view */}
      <Box sx={{
        pt: { xs: '', md: '60px', lg: '60px' }, pb: "20px", background: "#fff", display: { xs: 'block', sm: 'block', md: 'none' }
      }}>
        <Grid
          container
          spacing={2}
          sx={{
            borderBottom: "1px solid #DADADA",
            pb: "20px",
            "@media (max-width: 600px)": { padding: "20px" },
          }}
        >
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            Behind-the-Wheel Training Packages
          </Typography>


          <Typography variant="h5"



            sx={{
              textAlign: 'center',
              margin: '20px auto', // Center horizontally
              background: 'linear-gradient(45deg, #ba68c8, #f06292)',
              color: 'white !important', // Text color for readability
              fontSize: '25px',
              padding: '15px',
              borderRadius: '20px',
              maxWidth: 'fit-content', // Shrink to fit content
              width: 'fit-content', // Adjust to content
              animation: 'offerText 2s infinite ', // Add animation
              boxShadow: '0px 0px 15px 3px #8e24aa',
              '@keyframes offerText': {
                '0%': {
                  // boxShadow: '0px 0px 10px 2px #ff6f61',
                },
                '70%': {
                  boxShadow: '3px 3px 10px 1px #8e24aa'
                },
              },
            }}
          >

            Get 15% off this Spring season  Offer valid from Apr 01 to Apr 30, 2025!
          </Typography>
        </Grid>
        <div id="section-1"

        >

          <Stack spacing={2} sx={{ margin: '10px 0' }}>

            <Typography variant="h3" sx={{ width: '300px !important' }}>Gear Up for Your Driver's License!</Typography>
            <Typography sx={{ color: 'grey !important' }}>Select Hours - Pick Your Time Slots</Typography>

          </Stack>

          <Grid container alignItems={'center'} justifyContent={'center'} >

            {/* 1st block */}
            <Box
              sx={{
                border: '1px solid grey',
                width: '95%',
                margin: 'auto',
                padding: '10px'
              }}
            >
              <Grid container spacing={1} sx={{ height: '300px', overflow: 'scroll' }}>
                {BehindTheWheel.map((item, index) => (
                  <Grid item xs={6} key={index} style={{ borderBottom: '1px solid #d4d4d8' }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      // onClick={() => handleTabClick(item)}
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        border: selectedBehindCourse === item.packageid ? '2px solid #0063D3' : '1px solid grey',
                        borderRadius: '5px',
                        backgroundColor: selectedBehindCourse === item.packageid ? '#E3F2FD' : 'white',
                        transition: 'background 0.3s, transform 0.3s',
                      }}
                      spacing={2}
                    >
                      <Radio
                        checked={selectedBehindCourse === item.packageid}
                        onChange={() => handleTabClick(item)}
                        value={item.packageid}
                        name="behindTheWheel"
                        style={{
                          color: selectedBehindCourse === item.packageid ? '#0063D3' : 'default',
                        }}
                      />

                      <Stack justifyContent={'center'}>
                        <Typography variant="body1" style={{ flex: 1 }} onClick={() => handleTabClick(item)}>
                          {item.duration} Hours
                        </Typography>
                        {/* <Typography variant="h6" style={{ fontWeight: 'bold', color: 'gray' }} onClick={() => handleTabClick(item)}>
                          ${item.packageamount}
                          
                         


                          
                        </Typography> */}


                        <Stack>
                          {item.discount > 0 ?

                            <>

                              <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }} onClick={() => handleTabClick(item)}
                              >
                                ${item.discountprice}
                              </Typography>

                              <Typography sx={{ fontWeight: 'bold', color: 'gray', textDecoration: "line-through" }} onClick={() => handleTabClick(item)}
                              >
                                ${item.packageamount}
                              </Typography>

                            </>
                            :

                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }} onClick={() => handleTabClick(item)}
                            >
                              ${item.packageamount}
                            </Typography>


                          }



                        </Stack>
                      </Stack>

                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
            {/* 1st block */}



            {/* 2nd block */}
            <Grid item md={3.5} sx={
              {
                background: `url(${groupImageForMobile})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                marginTop: '20px',
                padding: '10px'
              }
            }>

              <Typography sx={{ height: '85px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="h3">COMBO OFFER FOR YOU</Typography>

              <Stack direction={'row'} justifyContent={'center'} >
                <Chip label={'BUNDLE UP & SAVE'} sx={{ background: '#f6cb00', color: 'black', fontSize: '25px', fontWeight: 500, padding: '30px', margin: '10px 0 0 0' }} />
              </Stack>


              {/* <Typography
                  variant="h4"
                  sx={{
                    margin: "0px 0px 15px",
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#FF4500', // Bright orange color
                    textDecoration: 'none',
                    cursor: 'default',
                    animation: 'pulse 1.5s infinite', // Pulsing effect
                    padding: '15px', // Add padding for a better look
                    borderRadius: '10px', // Round the corners
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)', // Subtle shadow for depth
                    background: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white background
                    whiteSpace: 'nowrap', // Prevent text wrapping
                    overflow: 'visible', // Ensure overflow is visible
                    maxWidth: '100%', // Allow full width utilization
                    width: '100%', // Full width
                  }}
                >
                  BUNDLE UP & SAVE
                </Typography> */}

              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: "center",
                  color: "white !important",
                  fontWeight: "bold",
                  margin: '10px 0'
                }}
              >
                Click Below Course And Buy as a Package
              </Typography>
              {/* <Typography
                  variant="h3"
                  sx={{ textAlign: "center", fontWeight: "600 !important" }}
                >
                  +
                </Typography> */}


              <Grid
                sx={{
                  display: "flex",
                  gap: "8px;",
                  flexDirection: "column",
                }}
              >
                {/* {renderCourses(Dui)} */}

                {/* {renderCourses(driversEducation)} */}

                {driversEducation?.map((item, i) => (
                  <Grid
                    key={i}
                    sx={{
                      backgroundColor: 'transparent', // Green background for unselected
                      color: 'black',
                      borderRadius: '10px',
                      // border: isSelected(item) ? '2px solid white' : '2px solid white',
                      cursor: 'pointer',
                      padding: '16px',
                      minWidth: '250px', // Set a minimum width to keep the cards uniform
                    }}
                    onClick={() => handleSelect(item)}
                  >
                    {console.log('courses item>>>', item)}
                    <Grid
                      sx={{
                        p: '20px',
                        backgroundColor: isSelected(item) ? 'white' : 'transparent', // Green background for unselected
                        boxShadow: '0 8px 20px 0 rgba(31, 38, 135, 0.17)',
                        backdropFilter: 'blur(2px)',
                        WebkitBackdropFilter: 'blur(2px)',
                        borderRadius: '10px',
                        border: isSelected(item) ? '2px solid white' : '2px solid white',
                      }}
                    >
                      <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Checkbox
                          checked={isSelected(item)}
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#26b170',
                            },
                          }}
                        />
                        {
                          item.producttype == 5 ?
                            <Typography
                              variant="h6"
                              fontWeight="500 !important"
                              sx={{ color: isSelected(item) ? 'black' : 'white !important' }}
                            >
                              {`${item?.packagename}`}
                            </Typography>
                            :
                            <Typography
                              variant="h6"
                              fontWeight="500 !important"
                              sx={{ color: isSelected(item) ? 'black' : 'white !important', width: '200px' }}
                            >
                              {/* {`${item?.duration} hours of ${item?.packagename}`} */}
                              {`${item?.packagename}`}
                            </Typography>
                        }



                        <Typography sx={{ color: isSelected(item) ? 'black' : 'white !important' }}>
                          <Stack direction="column">
                            {item.producttype == 4 && selectedBTWHour >= 6 ? (
                              <>
                                <Typography component="span" sx={{
                                  fontSize: '25px !important',
                                  color: isSelected(item) ? 'black' : 'white !important',
                                  fontWeight: 'bold',
                                  // textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                  // '-webkit-text-stroke': '0.5px black',
                                  // textStroke: '0.5px black'
                                }} >
                                  <b>${item.combopackageamount1}</b>
                                </Typography>
                                <Typography component="span" sx={{ textDecoration: 'line-through', color: isSelected(item) ? 'black' : 'white !important' }}>
                                  ${item.packageamount}
                                </Typography>
                              </>
                            ) : (
                              <Typography component="span" sx={{ color: isSelected(item) ? 'black' : 'white !important' }}>
                                <b>${item.packageamount}</b>
                              </Typography>
                            )}
                          </Stack>
                        </Typography>


                      </Stack>
                    </Grid>
                    {
                      item?.producttype === 3 &&
                      <>

                        <Divider sx={{ mb: 1 }} />
                        <div style={{ paddingLeft: '10px' }}>


                          <Typography variant="caption">
                            This defensive class purchased as a Package  may help with Insurance reduction only;
                            Teens under the age of 18 should take a 30-hr Driver’s Education class instead to meet Joshua law requirements.
                          </Typography>
                        </div>

                      </>

                    }

                  </Grid>
                ))}

                {/* <Typography
                  variant="h3"
                  sx={{ textAlign: "center", fontWeight: "600 !important" }}
                >
                  +
                </Typography> */}


                {/* {renderCourses(roadTesting)} */}

                {roadTesting?.map((item, i) => (
                  <Grid
                    key={i}
                    sx={{
                      backgroundColor: 'transparent', // Green background for unselected
                      color: 'black',
                      borderRadius: '10px',
                      // border: isSelected(item) ? '2px solid white' : '2px solid white',
                      cursor: 'pointer',
                      padding: '16px',
                      minWidth: '250px', // Set a minimum width to keep the cards uniform
                    }}
                    onClick={() => handleSelect(item)}
                  >
                    {console.log('courses item>>>', item)}
                    <Grid
                      sx={{
                        p: '20px',
                        p: '20px',
                        backgroundColor: isSelected(item) ? 'white' : 'transparent', // Green background for unselected
                        boxShadow: '0 8px 20px 0 rgba(31, 38, 135, 0.17)',
                        backdropFilter: 'blur(2px)',
                        WebkitBackdropFilter: 'blur(2px)',
                        borderRadius: '10px',
                        border: isSelected(item) ? '2px solid white' : '2px solid white',
                      }}
                    >
                      <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Checkbox
                          checked={isSelected(item)}
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#26b170',
                            },
                          }}
                        />
                        {
                          item.producttype == 5 ?
                            <Typography
                              variant="h6"
                              fontWeight="500 !important"
                              sx={{ color: isSelected(item) ? 'black' : 'white !important' }}
                            >
                              {`${item?.packagename}`}
                            </Typography>
                            :
                            <Typography
                              variant="h6"
                              fontWeight="500 !important"
                              sx={{ color: isSelected(item) ? 'black' : 'white !important' }}
                            >
                              {`${item?.duration} hours of ${item?.packagename}`}
                            </Typography>
                        }
                        {/* <Typography sx={{ color: isSelected(item) ? 'white' : 'black', }}>
                          {
                            selectedBTWHour ?
                              <b>${item?.combopackageamount1}</b> :
                              <b>${item.packageamount}</b>
                          }
                        </Typography> */}

                        <Typography sx={{ color: isSelected(item) ? 'black' : 'white !important' }}>
                          <Stack direction="column">
                            {selectedBTWHour ? (
                              <>
                                <Typography component="span" sx={{
                                  fontSize: '25px !important',
                                  color: isSelected(item) ? 'black' : 'white !important',
                                  fontWeight: 'bold',
                                  // textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                  // '-webkit-text-stroke': '0.5px black',
                                  // textStroke: '0.5px black'
                                }}>
                                  <b>${item.combopackageamount1}</b>
                                </Typography>
                                <Typography component="sapn" sx={{ textDecoration: 'line-through', color: isSelected(item) ? 'black' : 'white !important' }}>
                                  ${item.packageamount}
                                </Typography>
                              </>
                            ) : (
                              <Typography component="span" sx={{ color: isSelected(item) ? 'black' : 'white !important' }}>
                                <b>${item.packageamount}</b>
                              </Typography>
                            )}
                          </Stack>
                        </Typography>

                      </Stack>
                    </Grid>
                    {
                      item?.producttype === 3 &&
                      <>

                        <Divider sx={{ mb: 1 }} />
                        <div style={{ paddingLeft: '10px' }}>


                          <Typography variant="caption">
                            This defensive class purchased as a Package  may help with Insurance reduction only;
                            Teens under the age of 18 should take a 30-hr Driver’s Education class instead to meet Joshua law requirements.
                          </Typography>
                        </div>

                      </>

                    }

                  </Grid>
                ))}

                {/* <Typography
                  variant="h3"
                  sx={{ textAlign: "center", fontWeight: "600 !important" }}
                >
                  +
                </Typography> */}

                {/* {renderCourses(defensive)} */}


                {
                  defensive?.map((item, i) => (
                    <Grid
                      key={i}
                      sx={{
                        backgroundColor: 'transparent', // Green background for unselected
                        color: 'black',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        padding: '16px',
                        minWidth: '250px', // Set a minimum width to keep the cards uniform
                        marginBottom: '10px'
                      }}
                      onClick={() => handleSelect(item)}
                    >
                      {console.log('courses item>>>', item)}
                      <Grid
                        sx={{
                          p: '20px',
                          backgroundColor: isSelected(item) ? 'white' : 'transparent', // Green background for unselected
                          boxShadow: '0 8px 20px 0 rgba(31, 38, 135, 0.17)',
                          backdropFilter: 'blur(2px)',
                          WebkitBackdropFilter: 'blur(2px)',
                          borderRadius: '10px',
                          border: isSelected(item) ? '2px solid white' : '2px solid white',
                        }}
                      >
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                          <Checkbox
                            checked={isSelected(item)}
                            sx={{
                              color: 'white',
                              '&.Mui-checked': {
                                color: '#26b170',
                              },
                            }}
                          />
                          {
                            item.producttype == 5 ?
                              <Typography
                                variant="h6"
                                fontWeight="500 !important"
                                sx={{ color: isSelected(item) ? 'black' : 'white !important' }}
                              >
                                {`${item?.packagename}`}
                              </Typography>
                              :
                              <Typography
                                variant="h6"
                                fontWeight="500 !important"
                                sx={{ color: isSelected(item) ? 'black' : 'white !important' }}
                              >
                                {/* {`${item?.duration} hours of ${item?.packagename}`} */}
                                {`${item?.packagename}`}
                              </Typography>
                          }



                          <Typography sx={{ color: isSelected(item) ? 'black' : 'white !important' }}>
                            <Stack direction="column">
                              {!_.isNil(selectedBTWHour) && selectedBTWHour < 12 ? (
                                <>
                                  <Typography component="span" sx={{
                                    fontSize: '25px !important',
                                    color: isSelected(item) ? 'black' : 'white !important',
                                    fontWeight: 'bold',
                                    // textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                    // '-webkit-text-stroke': '0.5px black',
                                    // textStroke: '0.5px black'
                                  }}>
                                    <b>${item.combopackageamount1}</b>
                                  </Typography>
                                  <Typography component="span" sx={{ textDecoration: 'line-through', color: isSelected(item) ? 'black' : 'white !important' }}>
                                    ${item.packageamount}
                                  </Typography>
                                </>
                              ) : selectedBTWHour >= 12 ? (
                                <>
                                  <Typography component="span" sx={{
                                    fontSize: '25px !important',
                                    color: isSelected(item) ? 'black' : 'white !important',
                                    fontWeight: 'bold',
                                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                    '-webkit-text-stroke': '0.5px black',
                                    textStroke: '0.5px black'
                                  }}>
                                    <b>${item.combopackageamount2}</b>
                                  </Typography>
                                  <Typography component="span" sx={{ textDecoration: 'line-through' }}>
                                    ${item.packageamount}
                                  </Typography>
                                </>
                              ) : (
                                <Typography component="span">
                                  <b>${item.packageamount}</b>
                                </Typography>
                              )}
                            </Stack>
                          </Typography>



                        </Stack>
                      </Grid>
                      {
                        item?.producttype === 3 &&
                        <>

                          <Divider sx={{ mb: 1 }} />
                          <div style={{ paddingLeft: '10px' }}>

                            <Stack spacing={1}>

                              <Typography variant="caption" sx={{ color: 'white !important' }}>
                                <FiberManualRecordIcon sx={{ fontSize: "12px" }} /> &nbsp;
                                This defensive class purchased as a Package  may help with Insurance reduction only.
                              </Typography>

                              <Typography variant="caption" sx={{ color: 'white !important' }}>
                                <FiberManualRecordIcon sx={{ fontSize: "12px" }} /> &nbsp;
                                Teens under the age of 18 should take a <br /> 30-hr Driver’s Education class instead to meet Joshua law requirements
                              </Typography>


                            </Stack>
                          </div>

                        </>

                      }

                    </Grid>
                  ))
                }

              </Grid>



            </Grid>
            {/* 2nd block */}


            {
              !_.isEmpty(finalData) ?
                <Grid item xs={12} md={4}>
                  <Card>
                    {
                      <CardMedia
                        component="img"
                        image={finalData?.packages[0]?.image}
                        alt="Behind the Wheel"
                        height={200}
                        sx={{ objectFit: 'cover' }}
                      />
                    }

                    <CardContent sx={{
                      position: isSticky ? 'fixed' : 'relative', // Fixed position only when sticky
                      bottom: isSticky ? 0 : 'auto',
                      left: isSticky ? 0 : 'auto',
                      right: isSticky ? 0 : 'auto',
                      zIndex: isSticky ? 1000 : 'auto',
                      backgroundColor: 'white', // Add background color for visibility
                      transition: 'all 0.3s ease-in-out', // Smooth transition for changes
                    }}>

                      <Stack direction={'row'} justifyContent={'space-evenly'} alignItems={'center'} sx={{ marginBottom: '50px' }}>

                        <Box>
                          {finalData?.packages?.map((item, index) => (
                            <Stack key={index} >
                              <Typography variant="h6" sx={{ color: 'black !important', fontWeight: 400 }}>
                                {item.packagename}
                              </Typography>
                            </Stack>
                          ))}
                        </Box>

                        <Divider orientation="vertical" flexItem />

                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="center"
                        >

                          {/* {
                            priceHelper(Math.round(finalData?.discountprice), finalData?.finalcost) ? <Typography sx={{ textAlign: 'center !important', width: '100%' }}>
                              {
                                finalData?.comboid == 0 ?
                                  <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                    ${finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)}

                                  </span> :
                                  <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>
                                    ${finalData?.discountprice}
                                  </span>
                              }
                              &nbsp;&nbsp;

                            </Typography> : <Typography sx={{ width: '100%', borderRadius: { xs: '10px', md: '' } }}>

                              <Stack direction={'column'}>
                                {
                                  finalData?.comboid == 0 ?
                                    <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                      ${finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)}

                                    </span> :
                                    <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>
                                      ${finalData?.discountprice}
                                    </span>
                                }
                                &nbsp;&nbsp;

                                {
                                  finalData?.comboid == 0 ?
                                    <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                    </span> :
                                    <span
                                      style={{
                                        textDecoration: "line-through",
                                        fontSize: "20px",
                                        color: 'gray'
                                      }}
                                    >
                                      ${finalData?.finalcost}
                                    </span>
                                }
                              </Stack>

                            </Typography>
                          } */}





                          {
                            // single course
                            finalData?.comboid === 0 ? (
                              <Typography
                                sx={{
                                  fontWeight: "700 !important",
                                  fontSize: "30px !important",
                                  color: "black !important",
                                }}
                              >


                                ${finalData?.discountprice}
                              </Typography>

                            ) : (



                              // combo course


                              // no discount found  display discountprice only
                              // there is no discount 
                              finalData?.finalcost === finalData?.discountprice ? (
                                <Typography
                                  sx={{ fontWeight: "700 !important", fontSize: "30px !important", color: "black !important" }}
                                >
                                  ${finalData?.discountprice}
                                </Typography>
                              ) : (

                                // display amount with discount and origanl and saving price
                                <Stack direction={'column'} spacing={2} alignItems={'center'}>

                                  <Typography
                                    sx={{ fontWeight: "500 !important", fontSize: "24px !important", color: "gray !important", textDecoration: "line-through", marginRight: '10px !important' }}
                                  >

                                    ${finalData?.finalcost}
                                  </Typography>


                                  <Stack direction={'column'} spacing={2} alignItems={'center'}>
                                    <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>
                                      ${finalData?.discountprice}
                                    </span>
                                    <Alert icon={false} severity="warning">
                                      Save ${(
                                        Number(finalData?.packages?.reduce((total, item) => total + (item.packageamount || 0), 0)) -
                                        Number(finalData?.discountprice)
                                      ).toFixed(2)}
                                    </Alert>
                                  </Stack>

                                </Stack>
                              )
                            )
                          }






                        </Stack>

                      </Stack>



                      {
                        finalData?.packages?.length === 1 && [3, 4, 5].includes(finalData.packages[0].producttype) ? (
                          <Stack direction={'row'} >
                            <Button variant="contained" sx={{
                              position: isSticky ? 'fixed' : 'relative', // Fixed position only when sticky
                              bottom: isSticky ? 20 : 'auto',
                              left: 45,
                              right: 'auto',
                              zIndex: isSticky ? 1000 : 'auto',
                              transition: 'all 0.3s ease-in-out', // Smooth transition for changes
                              width: '80%',
                              // margin: 'auto'
                            }} onClick={() => handleKnowMoreclick(finalData.packages[0].producttype)}>Know more</Button>
                          </Stack>
                        ) : (
                          <Stack direction={'row'} spacing={2}>
                            {/* <Button
                              variant="outlined"
                              onClick={() => handleAddToCart()}
                              sx={{ mt: 2 }}
                            >
                              ADD TO CART
                            </Button> */}
                            <Button
                              variant="contained"
                              sx={{
                                position: isSticky ? 'fixed' : 'relative', // Fixed position only when sticky
                                bottom: isSticky ? 20 : 'auto',
                                left: 45,
                                right: 'auto',
                                zIndex: isSticky ? 1000 : 'auto',
                                transition: 'all 0.3s ease-in-out', // Smooth transition for changes
                                width: '80%',
                                // margin: 'auto'
                              }}
                              onClick={() => handleCheckOutOpen(finalData)}
                            >
                              BUY NOW
                            </Button>
                          </Stack>
                        )
                      }

                    </CardContent>
                  </Card>

                  <Card sx={{ mt: 4 }}>
                    <Typography sx={{ p: 2 }}>
                      <b style={{ fontWeight: '900 !important' }}>Please note : </b>Teens Age 16 & 17 are required to take the 30-hour Driver's Education class in order to take their Driver's License.
                    </Typography>
                  </Card>
                </Grid>


                :

                <Grid item xs={12} md={4}>
                  <Card>

                    <CardMedia
                      component="img"
                      image={'https://webnox.blr1.digitaloceanspaces.com/studentportal/Course-not-Found.jpg'}
                      alt="Behind the Wheel"
                      height={200}
                      sx={{ objectFit: 'cover' }}
                    />


                    <CardContent>


                      <React.Fragment >
                        <Typography sx={{ color: 'black !important', fontSize: '20px', fontWeight: 400 }}>
                        Course already purchased
                        </Typography>
                        <Typography sx={{ color: 'black !important', fontSize: '20px', fontWeight: 400 }}>
                          please choose another combo...
                        </Typography>

                      </React.Fragment>



                    </CardContent>
                  </Card>

                </Grid>
            }


            {/* <Register
                openStatus={registerStatus}
                setRegisterStatus={setRegisterStatus}
                finalData={finalData}
              /> */}

            <NewUserRegister
              openStatus={newUser}
              setRegisterStatus={setNewUser}
              finalData={finalData}
              setFinalData={setFinalData}
            />


          </Grid>

        </div>

        {/* <button onClick={() => setOpen(true)}>Click me</button> */}
      </Box >
      {/* mobile view */}



      {/* <AnimatedDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="custom-dialog-title"
        TransitionComponent={Transition}
        transitionDuration={{ enter: 200, exit: 200 }}
        keepMounted
      >
        <DialogTitle id="custom-dialog-title">Special Offer</DialogTitle>
        <DialogContent>
          {
            selectedBTWHour == 2 || selectedBTWHour == 4 ?
              <Typography>
                Unlock Your Full Potential! Upgrade to 6 hours of BTW classes and grab
                Drivers Education for just $95 (save $29!). More hours, more confidence,
                and bigger savings – get started today!
              </Typography>

              : selectedBTWHour == 8 || selectedBTWHour == 10 ? (
                <Typography>
                  Maximize your learning! Upgrade to 12 hours of BTW classes and get Defensive Driving absolutely FREE!
                  Don’t miss out on this exclusive offer!
                </Typography>
              )

                : null
          }

        </DialogContent>

        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={(event) => handleCheckboxChange(event)}
              />
            }
            label="Do not show again"
          />
          <Button variant="contained" color="error" onClick={handleClose}>Close</Button>
        </DialogActions>
      </AnimatedDialog> */}

      <UpsellingDialog
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        selectedBTWHour={selectedBTWHour}
        dontShowAgain={dontShowAgain}
        handleCheckboxChange={handleCheckboxChange}
      />

    </>
  );
}

export default DlPackages;
