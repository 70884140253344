/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Typography from "@mui/material/Typography";
import { Dialog, DialogTitle, DialogContent, Divider, Stack, Button, Checkbox, Grid, Box } from "@mui/material";
import { AiTwotoneClockCircle } from "react-icons/ai";
import Header from '../../components/Header';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';


const MyCart = () => {
    const tempid = localStorage.getItem('tempId')
    const [data, setData] = useState([])

    const [isSingle, setIsSingle] = useState([]);
    const [isCombo, setIsCombo] = useState([]);

    const fetchCart = async () => {
        try {
            const response = await axios.post(`${base_Url}/getcartitems`, {
                "tempcustomerid": tempid,
                "customerid": ""
            })
            if (response?.data?.status) {
                setData(response?.data?.response)
                const c1 = response?.data?.response?.filter((item) => item.comboid === 0);
                setIsSingle(c1);
                const c2 = response?.data?.response?.filter((item) => item.comboid !== 0);
                setIsCombo(c2);
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchCart()
    }, []);




    // const data = [
    //     {
    //         "cartid": 258,
    //         "tenantid": 0,
    //         "locationid": 0,
    //         "moduleid": 0,
    //         "comboid": 24,
    //         "comboimage": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg",
    //         "products": [
    //             {
    //                 "productid": 2,
    //                 "productname": "DUI",
    //                 "packageid": 5,
    //                 "packagename": "DUI",
    //                 "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //             },
    //             {
    //                 "productid": 3,
    //                 "productname": "Defensive",
    //                 "packageid": 6,
    //                 "packagename": "Defensive",
    //                 "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //             },
    //             {
    //                 "productid": 4,
    //                 "productname": "Drivers Education",
    //                 "packageid": 7,
    //                 "packagename": "Drivers Education",
    //                 "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //             }
    //         ],
    //         "customerid": 0,
    //         "tempcustomerid": 2,
    //         "productid": "",
    //         "packageid": "",
    //         "quantity": 0,
    //         "rate": "0.000000",
    //         "taxamount": "0.000000",
    //         "taxpercent": "0.000000",
    //         "totalamount": 2000,
    //         "cartstatus": "active",
    //         "createdby": 0,
    //         "created": "2024-06-13T18:44:48.000Z",
    //         "updated": "2024-06-13T18:44:48.000Z"
    //     },
    //     {
    //         "cartid": 258,
    //         "tenantid": 0,
    //         "locationid": 0,
    //         "moduleid": 0,
    //         "comboid": 25,
    //         "comboimage": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg",
    //         "products": [
    //             {
    //                 "productid": 2,
    //                 "productname": "DUI",
    //                 "packageid": 5,
    //                 "packagename": "DUI",
    //                 "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //             },
    //             {
    //                 "productid": 3,
    //                 "productname": "Defensive",
    //                 "packageid": 6,
    //                 "packagename": "Defensive",
    //                 "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //             },
    //         ],
    //         "customerid": 0,
    //         "tempcustomerid": 2,
    //         "productid": "",
    //         "packageid": "",
    //         "quantity": 0,
    //         "rate": "0.000000",
    //         "taxamount": "0.000000",
    //         "taxpercent": "0.000000",
    //         "totalamount": 2000,
    //         "cartstatus": "active",
    //         "createdby": 0,
    //         "created": "2024-06-13T18:44:48.000Z",
    //         "updated": "2024-06-13T18:44:48.000Z"
    //     },
    //     {
    //         "cartid": 231,
    //         "tenantid": 0,
    //         "locationid": 0,
    //         "moduleid": 0,
    //         "comboid": 0,
    //         "products": {
    //             "productid": 3,
    //             "productname": "Defensive",
    //             "packageid": 6,
    //             "packagename": "Defensive",
    //             "totalamount": "",
    //             "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //         },
    //         "customerid": 0,
    //         "tempcustomerid": 2,
    //         "productid": "",
    //         "packageid": "",
    //         "quantity": 0,
    //         "rate": "0.000000",
    //         "taxamount": "0.000000",
    //         "taxpercent": "0.000000",
    //         "totalamount": 500,
    //         "cartstatus": "active",
    //         "createdby": 0,
    //         "created": "2024-06-13T12:29:21.000Z",
    //         "updated": "2024-06-13T12:29:21.000Z"
    //     },
    //     {
    //         "cartid": 231,
    //         "tenantid": 0,
    //         "locationid": 0,
    //         "moduleid": 0,
    //         "comboid": 0,
    //         "products": {
    //             "productid": 2,
    //             "productname": "Dui",
    //             "packageid": 5,
    //             "packagename": "Dui",
    //             "totalamount": "",
    //             "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //         },
    //         "customerid": 0,
    //         "tempcustomerid": 2,
    //         "productid": "",
    //         "packageid": "",
    //         "quantity": 0,
    //         "rate": "0.000000",
    //         "taxamount": "0.000000",
    //         "taxpercent": "0.000000",
    //         "totalamount": 500,
    //         "cartstatus": "active",
    //         "createdby": 0,
    //         "created": "2024-06-13T12:29:21.000Z",
    //         "updated": "2024-06-13T12:29:21.000Z"
    //     },
    //     {
    //         "cartid": 231,
    //         "tenantid": 0,
    //         "locationid": 0,
    //         "moduleid": 0,
    //         "comboid": 0,
    //         "products": {
    //             "productid": 4,
    //             "productname": "Drivers Education",
    //             "packageid": 7,
    //             "packagename": "Drivers Education",
    //             "totalamount": "",
    //             "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //         },
    //         "customerid": 0,
    //         "tempcustomerid": 2,
    //         "productid": "",
    //         "packageid": "",
    //         "quantity": 0,
    //         "rate": "0.000000",
    //         "taxamount": "0.000000",
    //         "taxpercent": "0.000000",
    //         "totalamount": 500,
    //         "cartstatus": "active",
    //         "createdby": 0,
    //         "created": "2024-06-13T12:29:21.000Z",
    //         "updated": "2024-06-13T12:29:21.000Z"
    //     },
    //     {
    //         "cartid": 231,
    //         "tenantid": 0,
    //         "locationid": 0,
    //         "moduleid": 0,
    //         "comboid": 0,
    //         "products": {
    //             "productid": 3,
    //             "productname": "Defensive",
    //             "packageid": 6,
    //             "packagename": "Defensive",
    //             "totalamount": "",
    //             "image": "https://webnox.blr1.digitaloceanspaces.com/driving_school/Defensive-Driving-Inside-02-01-scaled.jpg"
    //         },
    //         "customerid": 0,
    //         "tempcustomerid": 2,
    //         "productid": "",
    //         "packageid": "",
    //         "quantity": 0,
    //         "rate": "0.000000",
    //         "taxamount": "0.000000",
    //         "taxpercent": "0.000000",
    //         "totalamount": 500,
    //         "cartstatus": "active",
    //         "createdby": 0,
    //         "created": "2024-06-13T12:29:21.000Z",
    //         "updated": "2024-06-13T12:29:21.000Z"
    //     },
    // ];



    const [comboId, setComboId] = useState([]);
    const [packageId, setPackageId] = useState([]);

    const handleCheckboxChange = (item, type) => {

        let newPackageIds = [...packageId];
        let newComboIds = [...comboId];

        if (type === 'combo') {
            if (comboId.includes(item.comboid)) {
                newComboIds = newComboIds.filter(comboId => comboId !== item.comboid);
            } else {
                newComboIds = [...newComboIds, item.comboid];
            }
            setComboId(newComboIds);
        } else {
            const singlePackageId = item.products.packageid;
            if (packageId.includes(singlePackageId)) {
                newPackageIds = newPackageIds.filter(packageId => packageId !== singlePackageId);
            } else {
                newPackageIds.push(singlePackageId);
            }
            setPackageId(newPackageIds);
        }


    };

    const handleClick = () => {
        console.log('comboId', comboId)
        console.log('packageId', packageId)

    }



    return (
        <div>
            <Header />
            <Box sx={{ marginLeft: '160px', marginRight: '160px', paddingTop: '120px' }}>
                <Typography sx={{ color: 'black !important', fontSize: '30px !important', fontWeight: '500 !important' }}>
                    Shopping Cart
                </Typography>

                <Grid container spacing={5}>
                    <Grid item xs={12} md={8}>
                        <Stack sx={{ mt: 2 }}>
                            <Typography sx={{ p: 2, color: 'black !important', fontSize: '23px !important', fontWeight: '300 !important' }} >Combo  ({isCombo?.length} courses in cart)</Typography>
                            <Divider sx={{ mb: 2 }} />
                            {isCombo.map((cart, index) => (
                                <div key={index}>
                                    <Stack sx={{ px: 2, pt: 1, pb: 1 }} direction={{ xs: 'column', md: 'row' }}
                                        justifyContent={'space-between'} alignItems={'center'}>
                                        <Stack direction={'row'} spacing={3}>
                                            <Checkbox
                                                checked={comboId.includes(cart.comboid)}
                                                onChange={() => handleCheckboxChange(cart, 'combo')} />
                                            <div style={{ border: '1px solid #80808080', borderRadius: '5px' }}>
                                                <img
                                                    src={cart.image}
                                                    alt={'demo'}
                                                    style={{
                                                        objectFit: 'contain',
                                                        width: '90px',
                                                        height: '55px',
                                                    }}
                                                />
                                            </div>
                                            <Stack spacing={0.5}>
                                                <Typography variant='subtitle1' sx={{ mt: 1 }}>
                                                    {cart.products.map(product => product.productname).join(' + ')}
                                                </Typography>
                                                <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }} spacing={2}>
                                                    <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }} spacing={0.5}>
                                                        <AiTwotoneClockCircle />
                                                        <Typography>{'N/A'} Hrs</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack spacing={1} alignItems={'center'}>
                                            <span style={{ fontWeight: "600", fontSize: "20px", color: "black" }}>
                                                ${cart?.finalcost}
                                            </span>
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ my: 2 }} />
                                </div>
                            ))}
                        </Stack>
                        <Stack sx={{ mt: 2 }}>
                            <Typography sx={{ p: 2, color: 'black !important', fontSize: '23px !important', fontWeight: '300 !important' }} >Single  ({isSingle?.length} courses in cart)</Typography>
                            <Divider />
                            {isSingle.map((cart, index) => (
                                <div key={index}>
                                    <Stack sx={{ px: 2, pt: 1, pb: 1 }} direction={{ xs: 'column', md: 'row' }}
                                        justifyContent={'space-between'} alignItems={'center'}>
                                        <Stack sx={{ px: 2, pt: 1, pb: 1 }} direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                            <Checkbox
                                                checked={packageId.includes(cart?.products?.packageid)}
                                                onChange={() => handleCheckboxChange(cart, 'single')} />
                                            <Stack direction={'row'} spacing={3}>
                                                <div style={{ border: '1px solid #80808080', borderRadius: '5px' }}>
                                                    <img
                                                        src={cart.image}
                                                        alt={cart.products.productname}
                                                        style={{
                                                            objectFit: 'contain',
                                                            width: '90px',
                                                            height: '55px',
                                                        }}
                                                    />
                                                </div>
                                                <Stack>
                                                    <Typography>{cart.products.productname}</Typography>
                                                    <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }} spacing={0.5}>
                                                        <AiTwotoneClockCircle />
                                                        <Typography>{cart.products.duration || 'N/A'} Hrs</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        <Stack spacing={1} alignItems={'center'}>
                                            <span style={{ fontWeight: "600", fontSize: "20px", color: "black" }}>
                                                ${cart?.finalcost}
                                            </span>
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ mb: 2 }} />
                                </div>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ mt: 4, color: 'black !important', fontSize: "20px !important", }} >Total</Typography>
                        <Typography sx={{ mt: 0, color: 'black !important', fontSize: "47px !important", fontWeight: "600" }} >$840</Typography>
                        <Typography
                            sx={{ textDecoration: 'line-through', fontWeight: "600", fontSize: "20px", color: "gray !important" }}
                        >
                            $2000
                        </Typography>
                        <Typography>45% off</Typography>
                        <Box sx={{ width: '300px', mt: 1 }} >
                            <Button variant='contained' color='primary' fullWidth onClick={() => handleClick()}>Checkout</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default MyCart;
