import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Typography, Card, CardMedia, Button, Stack } from "@mui/material";

import PlaceIcon from '@mui/icons-material/Place';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Register from '../Register/Register';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';
import GreenBanner from '../BaseUrl/GreenBanner';

import blueBox from '../../assets/images/home/footban2.jpg';

import moment from "moment";
import Banners from './Banners';
import RlthousantBg from "../../assets/images/roadTesting/RlthousantBg.png";
import NewUserRegister from '../Register/NewUserRegister';
import { googleAnalyticsFunction } from '../googleanalytics/googleanalytics';


import { Toaster, toast } from "sonner";

const styles = {
    greenBox: {
        background: `url(${RlthousantBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '30px 80px', marginTop: '40px',

        // textAlign: 'center',
        '@media (max-width: 900px)': {
            padding: '30px',
            marginTop: '20px'
        },
        // height: '300px'
    },
    packageBox: {
        boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px',
        '@media (max-width: 900px)': { marginTop: '40px', },
    },
    scheduleBox: {
        display: 'flex',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            gap: '20px',
        },
    },

    blueBox: {
        marginTop: '30px',
        background: `url(${blueBox}) no-repeat top center`,
        backgroundSize: 'cover', padding: '30px',
        borderRadius: '10px',
    },

    blueBoxText: {
        color: '#fff !important',
        background: '#00000054',
        padding: '10px 10px',
        borderRadius: '10px',
        textAlign: 'center',
        fontWeight: '400 !important',
    }
}

const programsData = [
    {
        prmTop1: "Starting",
        prmTop2: "$360",
        prmTittle: "DUI Risk Reduction class (Location)",
        prmTittleSub: "20 hours",
        prmDescription: "<b>$100</b> (for Assessment Questionnaire) <b>+ $260</b> (for class and book)",
        prmDescription1: "(Mandated by the Georgia Department of Driver Services (DDS).",
    },
];










const DrrzPackages = () => {


    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },
            duration: 3000,
            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }

    const [roadTesting, setRoadTesting] = useState([])
    const fetchPackages = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllCourse`, {
                type: ''
            })
            if (response.data.message === "success") {
                console.log('response', response?.data?.response)

                const course5 = response?.data?.response[1]
                setRoadTesting(course5)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchPackages()
    }, [])
    console.log('roadTesting', roadTesting)



    const [registerStatus, setRegisterStatus] = useState(false)

    const [finalData, setFinalData] = useState({})

    const [newUser, setNewUser] = useState(false)


    const isStudentExist = JSON.parse(localStorage.getItem('Student'))

    const fetchcheckPackageisExist = async (packageid) => {

        const isLoggedIn = localStorage.getItem('Student')


        try {
            const response = await axios.post(`${base_Url}/checkPackageisExist`, {
                packageid: packageid,
                studentid: isStudentExist?.customerid
            })
            if (response.data.message === 'success') {
                // console.log('response', response?.data?.response[0])

                const dataForGTM = response?.data?.response

                const courseData = dataForGTM?.packages?.map((val) => {
                    return {
                        productName: val?.packagename,
                        productPrice: val?.packageamount
                    }
                })

                googleAnalyticsFunction(dataForGTM, courseData)


                setFinalData(response?.data?.response)
                // setRegisterStatus(true)


                // if (isLoggedIn == null) {
                setNewUser(true)
                // }
                // else {
                //     setRegisterStatus(true)
                // }


            } else {
                setFinalData({})
                failure('course already purchased')
            }
        } catch (error) {
            console.log(error)
        }
    }



    console.log('finalData', finalData)






    const getDaysWeekdays = (id) => {
        switch (id) {
            case "1":
                return "Monday";
            case "2":
                return "Tuesday";
            case "3":
                return "Wednesday ";
            case "4":
                return "Thursday ";
            case "5":
                return "Friday ";

            default:
                return null;
        }
    };

    const getDaysWeekend = (id) => {
        switch (id) {
            case "6":
                return "Saturday";
            case "0":
                return "Sunday";

            default:
                return null;

        }
    };

    // Ensure roadTesting and scheduleDetails are defined
    const weekdaysSchedule = roadTesting?.scheduleDetails?.filter(item => ["1", "2", "3", "4", "5"].includes(item.dayname)) || [];
    const weekendSchedule = roadTesting?.scheduleDetails?.filter(item => ["0", "6"].includes(item.dayname)) || [];


    return (
        <>
            <Box sx={{ py: "50px", }}>


                <Toaster
                    toastOptions={{
                        duration: 3000,
                        style: {
                            cursor: 'pointer',

                        },
                        // Clickable setting ensures that clicking the toast will close it
                        ariaLive: 'assertive',
                        position: 'top-center',

                    }}
                />
                <Container>


                    <Typography variant="h4" > Package and Curriculum </Typography>


                    <Grid container spacing={2} mt={4}>

                        <Grid item md={7}  >

                            {/* first row */}
                            <Grid sx={{ border: '1px solid #E4E4E4', padding: '20px', borderRadius: '10px', mb: 2 }}>


                                <Grid sx={{ display: 'flex', gap: '20px', flexDirection: 'column', }}>
                                    <Grid sx={{ background: '#EFF7FF', padding: '20px' }}>
                                        <Typography mb="10px" variant='h2'> NEEDS Assessment Questionnaire—$100</Typography>
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● Georgia Department of Driver Services (DDS) requires that you complete this questionnaire prior to the start of your class.</Typography>
                                        <br />
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● A 20-30 minutes test which can be completed from the comfort of your home.</Typography>
                                        <br />
                                        {/* <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● Link to the questionnaire will be sent to the email provided during purchase.</Typography> */}
                                    </Grid>

                                    <Grid item md={5} id='Course' sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }} >
                                        {/* {programsData.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} sm={11} md={11} sx={styles.packageBox}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line"></Grid>

                                        <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                {program.prmTop1}
                                            </Typography>
                                            <Typography variant="h3" sx={{ color: "#fff !important" }}>
                                                {program.prmTop2}
                                            </Typography>
                                        </Grid>

                                        <Grid className="prgrm_con">

                                            <Grid className="prgrm_txt">
                                                <Grid className="" style={{ padding: "100px 20px 0px" }}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            fontWeight: "600 !important",
                                                        }}
                                                    >
                                                        {program.prmTittle}
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            margin: "0px 0px 16px",
                                                            fontWeight: "600 !important",
                                                            color: "var(--primary) !important"
                                                        }}
                                                    >
                                                        {program.prmTittleSub}
                                                    </Typography>
                                                    <Typography component="f14" dangerouslySetInnerHTML={{ __html: program.prmDescription.replace(/<br\s*\/?>/mg, "<br/>", "'", "<b>") }}></Typography>
                                                    <Typography component="f14" dangerouslySetInnerHTML={{ __html: program.prmDescription1.replace(/<br\s*\/?>/mg, "<br/>", "'", "<b>") }}></Typography>
                                                </Grid>

                                                <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                    <a className="btns btn_1 hvr-bounce-to-right">
                                                        GET NOW
                                                    </a>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))} */}

                                        {roadTesting?.courseDetails?.map((program, index) => (
                                            <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                                <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                                    <Grid className="prgrm_box_line"></Grid>

                                                    {/* <Grid className="prgrm_top_con">
                                                        <Typography sx={{ color: "#fff !important" }}>
                                                            Starting
                                                        </Typography>
                                                        <Typography
                                                            variant="h3"
                                                            sx={{ color: "#fff !important" }}
                                                        >
                                                            ${program.packageamount}
                                                        </Typography>
                                                    </Grid> */}

                                                    <Grid className="prgrm_img" style={{ padding: "10px" }} >
                                                        <CardMedia
                                                            className=""
                                                            component="img"
                                                            height="140px"
                                                            width="100%"
                                                            alt="program Image"
                                                            src={roadTesting.productimage}
                                                            sx={{ objectFit: 'cover' }}
                                                        />
                                                    </Grid>

                                                    {/* <Grid className="prgrm_con"> */}

                                                    <Grid className="prgrm_txt">
                                                        <Grid className="" style={{ padding: "10px 20px 0px" }}>
                                                            <Typography
                                                                variant="caption"
                                                                sx={{
                                                                    fontSize: "18px !important",
                                                                    margin: "0px 0px 16px",
                                                                }}
                                                            >
                                                                {program.packagename}
                                                            </Typography>

                                                            <Typography
                                                                variant="h3"
                                                            // sx={{ color: "#fff !important" }}
                                                            >
                                                                ${program.packageamount}
                                                            </Typography>

                                                            {/* <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    fontSize: "18px !important",
                                                                    margin: "0px 0px 16px",
                                                                    fontWeight: "600 !important",
                                                                    color: "var(--primary) !important"
                                                                }}
                                                            >
                                                                {program.duration} Hrs
                                                            </Typography>


                                                            <Typography sx={{ fontSize: "12px !important" }}>
                                                                {roadTesting.description}
                                                            </Typography> */}
                                                        </Grid>

                                                        {/* <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                            <a className="btns btn_1 hvr-bounce-to-right">
                                                                GET NOW
                                                            </a>
                                                        </Grid> */}

                                                        <Stack sx={{ padding: '10px' }}>
                                                            <Button
                                                                onClick={() => fetchcheckPackageisExist([program.packageid])}
                                                                variant="contained"
                                                                sx={{
                                                                    margin: '20px 0 0 0',
                                                                    padding: '10px 20px',
                                                                    fontSize: '17px',
                                                                    '&:hover': {
                                                                        backgroundColor: 'black',
                                                                        color: 'white'
                                                                    }
                                                                }}
                                                            >REGISTER</Button>
                                                        </Stack>

                                                    </Grid>


                                                    <Grid
                                                            container
                                                            direction="column"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{
                                                                boxShadow: "1px 1px 10px 3px #0000001f",
                                                                border: "3px solid #bb1414",
                                                                borderRadius: "12px",
                                                                padding: "24px",
                                                                backgroundColor: "#f9f9f9",
                                                                maxWidth: "300px",
                                                                margin: "40px auto",
                                                                textAlign: "center",
                                                                // marginLeft: "38px"
                                                            }}
                                                        >
                                                            <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
                                                                Weeknight Classes Bookings Open
                                                            </Typography>
                                                            <Typography variant="h6" color="textSecondary">
                                                                Starting <strong>April 07</strong>
                                                            </Typography>
                                                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                                                <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Monday to Friday  <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>06:00 pm - 10:00 pm</Typography>
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    backgroundColor: "#FFD700",
                                                                    padding: "8px 16px",
                                                                    borderRadius: "8px",
                                                                    display: "inline-block",
                                                                    marginTop: "12px",
                                                                }}
                                                            >
                                                                <Typography variant="body2" color="white" fontWeight="bold">
                                                                    Grab Your Spots <br /> Quickly!
                                                                </Typography>
                                                            </Box>
                                                        </Grid>


                                                    {/* </Grid> */}
                                                </Grid>
                                            </Grid>
                                        ))}

                                    </Grid>

                                    <Grid sx={{ background: '#F2FFF9', padding: '20px' }}>
                                        <Typography mb="10px" variant='h2'>20-hour DUI/Risk Reduction Class—$260</Typography>
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● Once you've completed the assessment, it's time for the 20-hour duration DUI/ Risk Reduction course.</Typography>
                                        <br />
                                        <Typography component='f14' sx={{ fontWeight: '400 !important' }}>● The 20-hour course must be taken consecutively. Weekday and Weekend Class schedules available and can be taken remotely via Live ZOOM or In-person..</Typography>


                                    </Grid>
                                </Grid>


                                <Typography sx={{ mt: 2 }}>
                                    Total fee for 20-hour DUI Risk Reduction class is $360 ($100 for Assessment Questionnaire + $260 for class and book). Mandated by the Georgia Department of Driver Services (DDS).
                                </Typography>
                            </Grid>



                            {/* second row */}
                            <div style={{ border: '1px solid #E4E4E4', padding: '20px', borderRadius: '10px', }}>


                                <Typography component="f18w600" sx={{ mb: 2 }}>Class Schedules</Typography>
                                <Grid sx={styles.scheduleBox}>

                                    <Grid sx={{ width: '100%', mt: 2 }}>
                                        <Typography marginBottom="20px" component="f18w600">Weekdays (Tue – Thurs)</Typography>
                                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '10px 0px', mt: '10px', }}>
                                            <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Tuesday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>10:00 am - 7:00 pm</Typography></Grid>
                                            <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Wednesday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>10:00 am - 7:00 pm</Typography></Grid>
                                            <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Thursday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>10:00 am - 2:00 pm</Typography></Grid>
                                        </Grid>
                                    </Grid>



                                    <Grid sx={{ width: '100%', mt: 2 }}>
                                        <Typography mb="10px" component="f18w600">Weekends (Fri – Sun)</Typography>
                                        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '10px 0px', mt: '10px', width: '100%', }}>
                                            <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Friday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>6:00 pm - 10:00 pm</Typography></Grid>
                                            <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Saturday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:00 am - 6:00 pm</Typography></Grid>
                                            <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Sunday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:00 am - 6:00 pm</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Typography sx={{ marginTop: "20px" }}>
                                    Certificates from the Georgia Department of Driver Services will be Emailed the next day by 05:30 pm from 1 Act Driving Schools.
                                </Typography>

                            </div>

                        </Grid>

                        <Grid item md={3.5} id='Course' sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }} >
                            {/* {programsData.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} sm={11} md={11} sx={styles.packageBox}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line"></Grid>

                                        <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                {program.prmTop1}
                                            </Typography>
                                            <Typography variant="h3" sx={{ color: "#fff !important" }}>
                                                {program.prmTop2}
                                            </Typography>
                                        </Grid>

                                        <Grid className="prgrm_con">

                                            <Grid className="prgrm_txt">
                                                <Grid className="" style={{ padding: "100px 20px 0px" }}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            fontWeight: "600 !important",
                                                        }}
                                                    >
                                                        {program.prmTittle}
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontSize: "18px !important",
                                                            margin: "0px 0px 16px",
                                                            fontWeight: "600 !important",
                                                            color: "var(--primary) !important"
                                                        }}
                                                    >
                                                        {program.prmTittleSub}
                                                    </Typography>
                                                    <Typography component="f14" dangerouslySetInnerHTML={{ __html: program.prmDescription.replace(/<br\s*\/?>/mg, "<br/>", "'", "<b>") }}></Typography>
                                                    <Typography component="f14" dangerouslySetInnerHTML={{ __html: program.prmDescription1.replace(/<br\s*\/?>/mg, "<br/>", "'", "<b>") }}></Typography>
                                                </Grid>

                                                <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                    <a className="btns btn_1 hvr-bounce-to-right">
                                                        GET NOW
                                                    </a>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))} */}

                            {roadTesting?.courseDetails?.map((program, index) => (
                                <Grid className="startTestingBox" item key={index} xs={11} md={10} sx={{ boxShadow: '1px 1px 6px 2px #0000001f', border: '1px solid #0000001f', marginLeft: 'auto', marginTop: '20px', marginBottom: '60px', }}>
                                    <Grid className="program_box_main startTestingBoxMain" sx={{}}>
                                        <Grid className="prgrm_box_line"></Grid>

                                        {/* <Grid className="prgrm_top_con">
                                            <Typography sx={{ color: "#fff !important" }}>
                                                Starting
                                            </Typography>
                                            <Typography
                                                variant="h3"
                                                sx={{ color: "#fff !important" }}
                                            >
                                                ${program.packageamount}
                                            </Typography>
                                        </Grid> */}

                                        <Grid className="prgrm_img" style={{ padding: "10px" }} >
                                            <CardMedia
                                                className=""
                                                component="img"
                                                height="140px"
                                                width="100%"
                                                alt="program Image"
                                                src={roadTesting.productimage}
                                                sx={{ objectFit: 'cover' }}
                                            />
                                        </Grid>

                                        {/* <Grid className="prgrm_con"> */}

                                        <Grid className="prgrm_txt">
                                            <Grid className="" style={{ padding: "10px 20px 0px" }}>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: "18px !important",
                                                        margin: "0px 0px 16px",
                                                    }}
                                                >
                                                    {program.packagename}
                                                </Typography>

                                                <Typography
                                                    variant="h3"
                                                // sx={{ color: "#fff !important" }}
                                                >
                                                    ${program.packageamount}
                                                </Typography>

                                                {/* <Typography
                                                    variant="h4"
                                                    sx={{
                                                        fontSize: "18px !important",
                                                        margin: "0px 0px 16px",
                                                        fontWeight: "600 !important",
                                                        color: "var(--primary) !important"
                                                    }}
                                                >
                                                    {program.duration} Hrs
                                                </Typography>


                                                <Typography sx={{ fontSize: "12px !important" }}>
                                                    {roadTesting.description}
                                                </Typography> */}
                                            </Grid>
                                            {/* 
                                            <Grid onClick={() => fetchcheckPackageisExist([program.packageid])} className="prgrm_but" style={{ marginTop: "30px" }}>
                                                <a className="btns btn_1 hvr-bounce-to-right">
                                                    GET NOW
                                                </a>
                                            </Grid> */}

                                            <Stack sx={{ padding: '10px' }}>
                                                <Button
                                                    onClick={() => fetchcheckPackageisExist([program.packageid])}
                                                    variant="contained"
                                                    sx={{
                                                        margin: '20px 0 0 0',
                                                        padding: '10px 20px',
                                                        fontSize: '17px',
                                                        '&:hover': {
                                                            backgroundColor: 'black',
                                                            color: 'white'
                                                        }
                                                    }}
                                                >REGISTER</Button>
                                            </Stack>

                                        </Grid>
                                        {/* </Grid> */}
                                    </Grid>
                                </Grid>
                            ))}



                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    boxShadow: "1px 1px 10px 3px #0000001f",
                                    border: "3px solid #bb1414",
                                    borderRadius: "12px",
                                    padding: "24px",
                                    backgroundColor: "#f9f9f9",
                                    maxWidth: "500px",
                                    margin: "40px auto",
                                    textAlign: "center",
                                    marginLeft: "38px"
                                }}
                            >
                                <Typography variant="h5" fontWeight="bold" color="primary" gutterBottom>
                                    Weeknight Classes Bookings Open
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                    Starting <strong>April 07</strong>
                                </Typography>
                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                    <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Monday to Friday  <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>06:00 pm - 10:00 pm</Typography>
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundColor: "#FFD700",
                                        padding: "8px 16px",
                                        borderRadius: "8px",
                                        display: "inline-block",
                                        marginTop: "12px",
                                    }}
                                >
                                    <Typography variant="body2" color="white" fontWeight="bold">
                                        Grab Your Spots <br /> Quickly!
                                    </Typography>
                                </Box>
                            </Grid>


                        </Grid>


                        <NewUserRegister
                            openStatus={newUser}
                            setRegisterStatus={setNewUser}
                            finalData={finalData}

                        />



                        {/* <Register
                            openStatus={registerStatus}
                            setRegisterStatus={setRegisterStatus}
                            finalData={finalData}
                            classmode={'1'}
                        /> */}

                    </Grid>

                    {/* <Grid container spacing={2} sx={{ marginTop: '30px', '@media (max-width: 900px)': { marginTop: '0px', } }}>

                        <Grid item md={6} sx={{ '@media (max-width: 900px)': { width: '100% !important' } }}>
                            <Grid sx={styles.scheduleBox}>
                                <Grid sx={{ width: '100%', }}>
                                    <Typography marginBottom="20px" component="f18w600">Weekday Class Schedule</Typography>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '10px 0px', mt: '10px', }}>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Tuesday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Wednesday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Thursday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                    </Grid>
                                </Grid>

                                <Grid sx={{ width: '100%', }}>
                                    <Typography mb="10px" component="f18w600">Weekend Class Schedule</Typography>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '10px 0px', mt: '10px', width: '100%', }}>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Friday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Saturday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                        <Grid sx={{ display: 'flex', }}><Typography sx={{ width: '42%', fontSize: '14px !important', }}> <AccessTimeFilledIcon sx={{ color: '#CCCCCC', fontSize: '16px' }} /> Sunday</Typography> <Typography sx={{ fontSize: '14px !important', color: 'var(--primary) !important' }}>9:30 am - 4:30 pm</Typography></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid> */}

                    {/* <GreenBanner /> */}



                    <Grid sx={styles.blueBox}>
                        <Typography variant='h6' sx={styles.blueBoxText}>
                            <b>PLEASE NOTE:</b> Certificate of Completion are the same whether you take the class virtually via Zoom or In-person at our Office.
                        </Typography>
                    </Grid>

                    {/* <Banners /> */}


                </Container>
            </Box>
        </>
    )
}

export default DrrzPackages
