import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import drivingLesson from "../../assets/images/driving-lesson/deriving-lesson-bg.png"
import conex from "../../assets/images/driving-lesson/conex.png"

import Register from '../Register/Register'
import { useState } from 'react'



import dlRightVideo from "../../assets/videos/DUI Zoom.mp4"
import axios from 'axios'
import { base_Url } from '../BaseUrl/ApiUrl'
import imgg from "../../assets/arrow.png"
import NewUserRegister from '../Register/NewUserRegister'
import { googleAnalyticsFunction } from '../googleanalytics/googleanalytics'

import { Toaster, toast } from "sonner";

const styles = {
  bannerRight: {
    '@media (max-width: 900px)': {
      paddingTop: '30px',
    }
  },
  dlBanner: {
    flexGrow: 1, background: `url(${drivingLesson}) no-repeat top center`, backgroundSize: '100% 100%', padding: '150px 0px 80px',
    '@media (max-width: 900px)': {
      padding: '50px 0px 50px'
    }
  }
}

const DrrzBanner = () => {

  const failure = (data) => {
    toast.error(data, {
      position: 'top-center',
      style: { border: '1px solid red' },
      duration: 3000,
      cancel: {
        label: "x",
        // style: {
        //     backgroundColor: '#a8261d', 
        //     color: '#ffffff', 
        //     border: 'none', 
        //     padding: '2px 8px',
        //     borderRadius: '4px', 
        // },
        onClick: () => console.log('Cancel!'),
      },

    });
  }


  const [roadTesting, setRoadTesting] = useState([])
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/getAllCourse`, {
        type: ''
      })
      if (response.data.message === "success") {
        console.log('response', response?.data?.response)

        const course5 = response?.data?.response[1]
        setRoadTesting(course5)

      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [])
  console.log('roadTesting', roadTesting)



  const [registerStatus, setRegisterStatus] = useState(false)

  const [finalData, setFinalData] = useState({})

  const [newUser, setNewUser] = useState(false)
  const isStudentExist = JSON.parse(localStorage.getItem('Student'))

  const fetchcheckPackageisExist = async (packageid) => {

    const isLoggedIn = localStorage.getItem('Student')

    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: packageid,
        studentid: isStudentExist?.customerid
      })
      if (response.data.message === 'success') {
        // console.log('response', response?.data?.response[0])

        const dataForGTM = response?.data?.response

        const courseData = dataForGTM?.packages?.map((val) => {
          return {
            productName: val?.packagename,
            productPrice: val?.packageamount
          }
        })

        googleAnalyticsFunction(dataForGTM, courseData)


        setFinalData(response?.data?.response)
        // setRegisterStatus(true)

        // if (isLoggedIn == null) {
        setNewUser(true)
        // }
        // else {
        //   setRegisterStatus(true)
        // }


      } else {
        setFinalData({})
        failure('course already purchased')
      }
    } catch (error) {
      console.log(error)
    }
  }



  console.log('finalData', finalData)





  return (
    <>

      <Box sx={styles.dlBanner}>

        <Toaster
          toastOptions={{
            duration: 3000,
            style: {
              cursor: 'pointer',

            },
            // Clickable setting ensures that clicking the toast will close it
            ariaLive: 'assertive',
            position: 'top-center',

          }}
        />
        <Container>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item md={7}>
              {/* <Typography
                variant='h5'
                component=""
                sx={{
                  fontWeight: "400 !important",
                  lineHeight: "1.5",
                  color: "#fff !important",
                }}
              >
                Take The Fast Lane To Responsible Driving
              </Typography> */}

              <Typography sx={{ color: 'white !important', fontWeight: 'bold' }}>Court Approved/DDS Certified</Typography>


              <Typography
                variant="h1"
                sx={{ color: "var(--yellow) !important", mt: '20px', }}
              >
                DUI / Risk reduction (Zoom)
              </Typography>

              <Grid sx={{ mt: "30px", display: 'flex', flexDirection: 'column', gap: '10px', }}>

                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    Let's call it a Learning curve, Not a Dead End!
                  </Typography>
                </Grid>
                <Grid sx={{ display: "flex", }}>
                  <img src={conex} style={{ width: "20px", height: "20px", margin: "6px 6px 0px 0px", }} /> &nbsp;
                  <Typography color="#fff !important" fontWeight="400 !important" >
                    Our Expert instructors help you Navigate it with Ease
                  </Typography>
                </Grid>

              </Grid>

              <Button variant="outlined" className='greenButAni' onClick={() => fetchcheckPackageisExist([roadTesting?.courseDetails[0]?.packageid])}>
                REGISTER NOW
              </Button>

              <NewUserRegister
                openStatus={newUser}
                setRegisterStatus={setNewUser}
                finalData={finalData}
              />

              {/* <Register
                openStatus={registerStatus}
                setRegisterStatus={setRegisterStatus}
                finalData={finalData}
                classmode={'1'}
              /> */}
            </Grid>

            <Grid item md={5}>
              <CardMedia className="" component="img" alt="DD Zoom" src={imgg} sx={styles.bannerRight} />
              {/* <CardMedia component="video" autoPlay loop muted src={dlRightVideo} sx={styles.bannerRight} /> */}
            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  )
}

export default DrrzBanner


