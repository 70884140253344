import AWS from 'aws-sdk';

const S3_BUCKET = 'webnox';
const REGION = 'blr1';
const ACCESS_KEY = 'DO00KMPLVC4GKKLNCNNR';
const SECRET_ACCESS_KEY = '5aV2hAO5XqMAm5gHQbsOjqBPbfO7NZo+KwSEQnQd/tk';
const FOLDER_NAME = 'studentportal/';
const ACTIVITY_NAME = 'registeration/';

const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
    region: REGION,
    endpoint: `https://${REGION}.digitaloceanspaces.com`
});

const uploadImagesToS3 = async (item) => {
    const file = item.doc;
    if (!file) return item;

    const params = {
        Bucket: S3_BUCKET,
        Key: `${FOLDER_NAME}${ACTIVITY_NAME}${file.name}`,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type
    };

    const response = await s3.upload(params).promise();

    return {
        ...item,
        s3Link: response.Location
    };
};

const DigitalOcean = (docInfo) => {
    // console.log('docInfo',docInfo)
    return docInfo.map(item => uploadImagesToS3(item));
};

export default DigitalOcean;
