/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { IoMdClose } from "react-icons/io";
import {
    Button,
    FormHelperText,
    Grid,
    Link,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack, Typography, DialogTitle, Dialog, DialogContent, IconButton, TextField, Divider,
} from '@mui/material';
import { base_Url } from '../../pages/BaseUrl/ApiUrl'
import axios from 'axios';
import _ from 'lodash'
import OtpInput from 'react-otp-input';
import { Toaster, toast } from "sonner";

const ForgotPassword = ({ passwordOpen, setPasswordOpen, Email }) => {
    const success = (data) => {
        toast.success(data, {
            position: 'top-center',
            style: { backgroundColor: 'green', color: 'white' },

            cancel: {
                label: "close",
                onClick: () => console.log('Cancel!'),
            },
        });
    }

    const failure = (data) => {
        toast.error(data, {
            position: 'top-center',
            style: { border: '1px solid red' },

            cancel: {
                label: "x",
                // style: {
                //     backgroundColor: '#a8261d', 
                //     color: '#ffffff', 
                //     border: 'none', 
                //     padding: '2px 8px',
                //     borderRadius: '4px', 
                // },
                onClick: () => console.log('Cancel!'),
            },

        });
    }


    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});

    const HandleResend = async () => {
        try {
            const response = await axios.post(`${base_Url}/forgotPassword`, {
                email: Email,
                roleid: 2

            })
            if (response.data.status) {
                success(response.data.message)
            }
            else {
                failure(response.data.message)
            }
        } catch (error) {
            console.log('error', error)
        }
    };

    const [OTPValue, setOTPValue] = useState(false)
    const handleSubmitOTP = async () => {
        // event.preventDefault();
        const validationErrors = {};

        // OTP validation
        if (otp.length !== 6 || !/^\d{6}$/.test(otp)) {
            validationErrors.otp = 'OTP must be a 6-digit number';
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {

            try {
                const response = await axios.post(`${base_Url}/checkValidUser`, {
                    email: Email,
                    otp: otp
                })
                if (response.data.status) {
                    setOTPValue(true)
                    success(response.data.message)
                }
                else {
                    failure(response.data.message)
                }
            } catch (error) {
                console.log('error', error)
            }

        }
    };

    const handleClick = async () => {
        // event.preventDefault();
        const validationErrors = {};

        if (newPassword.length < 6) {
            validationErrors.newPassword = 'Password must be at least 6 characters long';
        }

        if (newPassword !== confirmPassword) {
            validationErrors.confirmPassword = 'Passwords do not match';
        }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {

            console.log('otp', otp);
            console.log('confirmPassword', confirmPassword);
            console.log('newPassword', newPassword);

            try {
                const response = await axios.post(`${base_Url}/resetPassword`, {
                    email: Email,
                    password: newPassword,
                    roleid: 2

                })
                if (response.data.status) {

                    success(response.data.message)
                    setPasswordOpen(false)
                }
                else {
                    failure(response.data.message)
                }
            } catch (error) {
                console.log('error', error)
            }
        }
    };


    return (
        <div>
            <Toaster />
            <Dialog
                fullWidth
                maxWidth='sm'
                onClose={() => setPasswordOpen(false)}
                open={passwordOpen}
                sx={{ m: 2, p: 2 }}>

                <DialogTitle>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='h5' sx={{ color: 'black !important' }}>
                            Forgot Password
                        </Typography>
                        <IconButton onClick={() => setPasswordOpen(false)} sx={{ color: 'red' }}>
                            <IoMdClose />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <DialogContent>
                    {
                        !OTPValue &&

                        <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                            <Grid item xs={12} justifyContent={'center'} >
                                <Typography >
                                    Enter 6 digit OTP
                                </Typography>

                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    inputStyle={{
                                        width: '3rem',
                                        height: '2.9rem',
                                        borderRadius: '10%',
                                        border: '1px solid #ccc',
                                        padding: '.5rem',
                                        margin: '.5rem'
                                    }}
                                    numInputs={6}
                                    renderSeparator={' '}
                                    renderInput={(props) => <input {...props} />}
                                />

                                {errors.otp && (
                                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                        {errors.otp}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Button variant="contained" color="primary" fullWidth onClick={() => handleSubmitOTP()}>
                                    Confirm OTP
                                </Button>


                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Button variant="outlined" color="primary" fullWidth onClick={() => HandleResend()}>
                                    Resend OTP
                                </Button>
                            </Grid>
                        </Grid>
                    }

                    {
                        OTPValue &&

                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="New Password"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    error={!!errors.newPassword}
                                    helperText={errors.newPassword}
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Confirm New Password"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword}
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" fullWidth onClick={() => handleClick()}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    }

                </DialogContent>
            </Dialog>

        </div>
    )
}

export default ForgotPassword