/* eslint-disable no-unused-vars */
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box, Button, CardMedia, Grid, Typography, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import bannerbg from "../../assets/images/home/banner-bg-new.webp";
import banrht1 from "../../assets/images/home/banrht1.webp";
import banrht2 from "../../assets/images/home/banrht2.webp";   
import banrht3 from "../../assets/images/home/banrht3.webp";
import hmeslidelft from "../../assets/images/home/hmeslidelft-new.webp";
import banicon1 from "../../assets/images/home/banicon1.png";
import banicon2 from "../../assets/images/home/banicon2.png";
import banicon3 from "../../assets/images/home/banicon3.png";
// import Register from '../Register/Register'
import { useState } from 'react'
import { useEffect } from "react";

const Register = React.lazy(() => import('../Register/Register'));
const styles = {     

  homeButGreen: {
    background: "transparent",
    color: "var(--black)",
    border: "1px solid var(--black)",
    fontSize: "14px",
    fontWeight: "500",
    width: "150px",
    "&:hover": {
      background: "var(--green)",
      color: "var(--black)",
      border: "1px solid var(--black)",
    }
  },

  homeButGreenImage: {
    width: "20px",
    position: "absolute",
    left: "-10px",
  },

  homeButBlue: {
    background: "var(--primary)",
    color: "#fff",
    border: "1px solid var(--primary)",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "30px",
    padding: "10px 24px !important",
    "&:hover": {
      background: "#fff",
      color: "var(--primary)",
      border: "1px solid var(--primary)",
    },
  },

  slideTextContent: {
    '@media (max-width: 600px)': {
      width: '100% !important',
    }
  }

}

const HomeBanner = () => {

  const [registerStatus, setRegisterStatus] = useState(false)

  var settings = {
    dots: true,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    lazyLoad: "anticipated",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate()

  return (

    <div className="slider-container home_banner" loading="lazy" style={{ margin: "0px", backgroundImage: `url(${bannerbg})`,backgroundSize: "cover", backgroundPosition: "center" }}>
      <Slider {...settings}>

        {/* 1st-slider */}

        <Box sx={{ flexGrow: "1", margin: "0px" }} className="slider_main_bg">
          <CardMedia className="hmeslidelft" component="img" alt="slider left" src={hmeslidelft} loading="lazy"/>

          <Grid container spacing={2} className="slider_con">
            <Grid item md={1.8}></Grid>

            <Grid item md={5} className="slide_txt_con" sx={styles.slideTextContent}>
              <Stack>
                <Typography component="subtittle1">
                  Get <Typography sx={{ display: 'inline-block', fontSize: { xs: '25px !important', md: '45px !important' }, fontWeight: 'bold', color: '#0063d3 !important' }}  >Behind the Wheel</Typography> Lessons
                </Typography>
                <Typography component="subtittle1">
                  Get Ahead on the Road
                </Typography>
              </Stack>
              <Typography
                // sx={{ pt: '26px',fontWeight:'800 !important' }}
                sx={{ color: 'black !important', mt: 1 }}
                component="h5"
              > Driving lessons that Build Road-Ready Confidence,</Typography>
              <Typography
                // sx={{ pt: '26px',fontWeight:'800 !important' }}
                sx={{ color: 'black !important', mt: 1 }}
                component="h5"
              >  Mile by Mile</Typography>


              <Grid className="hme_ban_but" sx={{ marginTop: "30px" }}>
                <Button sx={styles.homeButGreen}><img src={banicon1} style={styles.homeButGreenImage} alt="banicon1"/>&nbsp; Safety </Button>
                <Button sx={styles.homeButGreen}><img src={banicon2} style={styles.homeButGreenImage} alt="banicon2"/>&nbsp; Affordable</Button>
                <Button sx={styles.homeButGreen}><img src={banicon3} style={styles.homeButGreenImage} alt="banicon3"/>&nbsp; Convenience</Button>
              </Grid>

              <Button sx={styles.homeButBlue} onClick={() => navigate('/schedule-driving-lessons-2', {
                state: { number: 2 }
              })} >REGISTER NOW</Button>
              <Register openStatus={registerStatus} setRegisterStatus={setRegisterStatus} />

            </Grid>

            <Grid item md={5} className="slide_rht_img">
              <CardMedia className="slide_img" component="img" alt="program Image" src={banrht1} loading="lazy"/>
            </Grid>

            <Grid item md={0.2}></Grid>

          </Grid>
        </Box>

        {/* 2nd-slider */}
        <Box sx={{ flexGrow: "1", margin: "0px" }} className="slider_main_bg">
          <CardMedia className="hmeslidelft img-fluid" component="img" alt="slider left" src={hmeslidelft} loading="lazy"/>
          <Grid container spacing={2} className="slider_con">

            <Grid item md={1.8}></Grid>

            <Grid item md={5} className="slide_txt_con" sx={styles.slideTextContent}>

              {/* <Typography component="subtittle1">
                Put the Brakes on Impaired Driving with Our DUI / Risk Reduction Course
              </Typography> */}

              {/* <Typography component="subtittle1">
                DDS Certified and Court Approved DUI/Risk Reduction Class
                In-Person or Live Remote
              </Typography> */}
              <Stack direction={'column'}>
                <Typography sx={{ display: 'inline-block', fontSize: { xs: '25px !important', md: '50px !important' }, fontWeight: 900, color: '#0063d3 !important' }}>
                  DUI/Risk Reduction Class
                </Typography>
                <Typography component="subtittle1">
                  DDS Certified and Court Approved
                </Typography>
                <Typography component="subtittle1">
                  In-Person or Live Remote
                </Typography>
              </Stack>

              <div className='abt_but' style={{ margin: '30px 0px', }}>
                <a onClick={() => navigate('/dui-risk-reduction-at-location')} className="btns btn_book hvr-bounce-to-right">Classes - Twice a week</a>
              </div>

              <Grid className="hme_ban_but" sx={{ marginTop: "30px" }}>
                <Button sx={styles.homeButGreen}><img src={banicon1} style={styles.homeButGreenImage} alt="banicon1"/>&nbsp; Safety </Button>
                <Button sx={styles.homeButGreen}><img src={banicon2} style={styles.homeButGreenImage} alt="banicon2"/>&nbsp; Affordable</Button>
                <Button sx={styles.homeButGreen}><img src={banicon3} style={styles.homeButGreenImage} alt="banicon3"/>&nbsp; Convenience</Button>
              </Grid>

              <Button sx={styles.homeButBlue} onClick={() => navigate('/dui-risk-reduction-course-remote', {
                state: { number: 2 }
              })} >REGISTER NOW</Button>

            </Grid>

            <Grid item md={5} className="slide_rht_img">
              <CardMedia className="slide_img" component="img" alt="program Image" src={banrht2} loading="lazy"/>
            </Grid>

            <Grid item md={0.2}></Grid>

          </Grid>
        </Box>

        {/* 3rd-slider */}
        <Box sx={{ flexGrow: "1", margin: "0px" }} className="slider_main_bg">
          <CardMedia className="hmeslidelft img-fluid" component="img" alt="slider left" src={hmeslidelft} loading="lazy"/>

          <Grid container spacing={2} className="slider_con">

            <Grid item md={1.8}></Grid>

            <Grid item md={5} className="slide_txt_con" sx={styles.slideTextContent}>

              {/* <Typography component="subtittle1">
                Anticipate the Unexpected with Defensive Driving Course
              </Typography> */}

              <Stack direction={'column'}>
                <Typography sx={{ fontSize: { xs: '25px !important', md: '50px !important' }, fontWeight: 900, color: '#0063d3 !important' }}>
                  Defensive Driving class
                </Typography>
                <Typography component="subtittle1">
                  DDS Certified and Court Approved
                </Typography>
                <Typography component="subtittle1">
                  In-Person or Live Remote
                </Typography>
              </Stack>


              <div className='abt_but' style={{ margin: '30px 0px', }}>
                <a onClick={() => navigate('/defensive-driving-at-location')} className="btns btn_book hvr-bounce-to-right">Classes - 7 days a week</a>
              </div>

              <Grid className="hme_ban_but" sx={{ marginTop: "30px" }}>
                <Button sx={styles.homeButGreen}><img src={banicon1} style={styles.homeButGreenImage} alt="banicon1" />&nbsp; Safety </Button>
                <Button sx={styles.homeButGreen}><img src={banicon2} style={styles.homeButGreenImage} alt="banicon2" />&nbsp; Affordable</Button>
                <Button sx={styles.homeButGreen}><img src={banicon3} style={styles.homeButGreenImage} alt="banicon3"/>&nbsp; Convenience</Button>
              </Grid>

              <Button sx={styles.homeButBlue} onClick={() => navigate('/defensive-driving-course-remote', {
                state: { number: 2 }
              })} >REGISTER NOW</Button>

            </Grid>

            <Grid item md={5} className="slide_rht_img">
              <CardMedia className="slide_img" component="img" alt="program Image" src={banrht3} loading="lazy"/>
            </Grid>

            <Grid item md={0.2}></Grid>
          </Grid>
        </Box>

      </Slider>
    </div>

  );
};

export default HomeBanner;