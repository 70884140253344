import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DynamicMeta from '../components/DynamicMeta';
// const Home = React.lazy(() => import("../pages/Home"));
// const DrivingLesson = React.lazy(() => import("../pages/Drivinglessons"));
// const RoadTesting = React.lazy(() => import("../pages/RoadTesting"));
// const DefensiveDrivingLocation = React.lazy(() => import("../pages/DefensiveDrivingLocation"));
// const ClinicalEvaluation = React.lazy(() => import("../pages/ClinicalEvaluation"));
// const DuiRiskReductionLocation = React.lazy(() => import("../pages/DuiRiskReductionLocation"));
// const Locations = React.lazy(() => import("../pages/Locations"));
// const DrivingLessonJoshuaLaw = React.lazy(() => import("../pages/DrivingLessonJoshuaLaw"));
// const DrivingLessonOnline = React.lazy(() => import("../pages/DrivingLessonOnline"));
// const DefensiveDrivingRemote = React.lazy(() => import("../pages/DefensiveDrivingRemote"));
// const DuiRiskReductionZoom = React.lazy(() => import("../pages/DuiRiskReductionZoom"));
// const ExamplePage = React.lazy(() => import("../pages/ExamplePage"));
// const Login = React.lazy(() => import("../pages/Login"));
// const Dashboard = React.lazy(() => import("../pages/DashBoard/Dashboard"));
// const SuccessPage = React.lazy(() => import("../pages/Register/SuccessPage"));
// const CancellationPolicy = React.lazy(() => import("../pages/CancellationPolicy"));
// const GDECScholarship = React.lazy(() => import("../pages/GDECScholarship"));
// const JoshuaLaw = React.lazy(() => import("../pages/JoshuaLaw"));
// const VictimImpact = React.lazy(() => import("../pages/VictimImpact"));
// const MyCart = React.lazy(() => import("../pages/AddtoCart/MyCart"));
// const MyProfile = React.lazy(() => import("../pages/Profile/MyProfile"));
// const InsurenceIndex = React.lazy(() => import("../pages/InsurenceReduction/Index"));
import Home from "../pages/Home";
import DrivingLesson from "../pages/Drivinglessons";
import RoadTesting from "../pages/RoadTesting";
import DefensiveDrivingLocation from "../pages/DefensiveDrivingLocation";
import ClinicalEvaluation from "../pages/ClinicalEvaluation";
import DuiRiskReductionLocation from "../pages/DuiRiskReductionLocation";
import Locations from "../pages/Locations";
import DrivingLessonJoshuaLaw from "../pages/DrivingLessonJoshuaLaw";
import DrivingLessonOnline from "../pages/DrivingLessonOnline";
import DefensiveDrivingRemote from "../pages/DefensiveDrivingRemote";
import DuiRiskReductionZoom from "../pages/DuiRiskReductionZoom";
import ExamplePage from "../pages/ExamplePage";
import Login from "../pages/Login";
import Dashboard from "../pages/DashBoard/Dashboard";
import SuccessPage from "../pages/Register/SuccessPage";
import CancellationPolicy from "../pages/CancellationPolicy";
import GDECScholarship from "../pages/GDECScholarship";
import JoshuaLaw from "../pages/JoshuaLaw";
import VictimImpact from "../pages/VictimImpact";
import MyCart from "../pages/AddtoCart/MyCart";
import MyProfile from "../pages/Profile/MyProfile";
import InsurenceIndex from "../pages/InsurenceReduction/Index";



// Scroll to top component

const ScrollToTop = () => {

  useEffect(() => {
    const onRouteChange = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('popstate', onRouteChange);

    return () => {
      window.removeEventListener('popstate', onRouteChange);
    };
  }, []);

  return null;
};

const MainRoutes = () => {
  return (

    <Router>
      <DynamicMeta />
      <ScrollToTop/>
      <Suspense fallback={<></>}>
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/schedule-driving-lessons-2" element={<DrivingLesson />} />
        <Route path="/drivers-education" element={<DrivingLessonJoshuaLaw />} />
        <Route path="/online-drivers-education" element={<DrivingLessonOnline />} />
        <Route path="/road-test-services" element={<RoadTesting />} />
        <Route path="/defensive-driving-at-location" element={<DefensiveDrivingLocation />} />
        <Route path="/defensive-driving-course-remote" element={<DefensiveDrivingRemote />} />
        <Route path="/dui-risk-reduction-at-location" element={<DuiRiskReductionLocation />} />
        <Route path="/dui-risk-reduction-course-remote" element={<DuiRiskReductionZoom />} />
        <Route path="/clinical-evaluation" element={<ClinicalEvaluation />} />
        <Route path="/our-locations" element={<Locations />} />
        <Route path="/privacy-policy-cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/example" element={<ExamplePage />} />
        <Route path="/checkout" element={<SuccessPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/gdec-scholarship" element={<GDECScholarship />} />
        <Route path="/joshua's-law-info" element={<JoshuaLaw />} />
        <Route path="/victim-impact-panel" element={<VictimImpact />} />
        <Route path="/cart" element={<MyCart />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/insurance-reduction" element={<InsurenceIndex />} />


        {/* Redirect for defined old URLs */}
        <Route
          path="/dui-risk-management-sandy-springs/"
          element={<Navigate to="/dui-risk-reduction-at-location" replace />}
        />
        <Route
          path="/register-for-alpharetta-dui-classes/"
          element={<Navigate to="/dui-risk-reduction-at-location" replace />}
        />
        <Route
          path="/defensive-driving-program-2/defensive-driving-classes-weeknights-georgia/2"
          element={<Navigate to="/defensive-driving-course-remote" replace />}
        />
        <Route
          path="/contact-us-2"
          element={<Navigate to="/our-locations" replace />}
        />
        <Route
          path="/dui-risk-management-lawrenceville/"
          element={<Navigate to="/dui-risk-reduction-at-location" replace />}
        />
        <Route
          path="/driving-school-and-driving-lessons/"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/about-us/"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/product/30-hour-drivers-education-class10-hours-behind-wheel/"
          element={<Navigate to="/schedule-driving-lessons-2" replace />}
        />

        {/* Redirect for assets */}
        <Route
          path="/wp-content/uploads/2018/07/pass-and-slow-down.jpg"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/wp-content/uploads/2018/07/20200516_140823.jpg"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/wp-content/uploads/2017/11/ignition-interlock.png"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/wp-content/uploads/2017/03/defensive-driving-school-ho.jpg"
          element={<Navigate to="/defensive-driving-course" replace />}
        />
        <Route
          path="/wp-content/uploads/2018/07/blinker.jpg"
          element={<Navigate to="/" replace />}
        />
        <Route
          path="/wp-content/uploads/2018/07/joker.png"
          element={<Navigate to="/" replace />}
        />

        {/* Catch-all route for undefined URLs */}
        <Route path="*" element={<Navigate to="/" replace />} />

      </Routes>
      </Suspense>
    </Router>

  );
};

export default MainRoutes;