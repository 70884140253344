import React from 'react'
import { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, CardMedia, Stack } from "@mui/material";
import axios from "axios";

import PlaceIcon from '@mui/icons-material/Place';
import { base_Url } from '../pages/BaseUrl/ApiUrl';


const LocationCard = ({id}) => {


    const [branchLocations, setBranchLocation] = useState([])

    const fetchLocations = async (id) => {
        try {
            const response = await axios.post(`${base_Url}/getAllBranches`, {
                courseid : id ? id : ''
            })
            if (response.data.status) {
                // const newlo= response?.data?.response.filter(item => item?.applocationid !== 4)
                setBranchLocation(response?.data?.response)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        fetchLocations(id)

    }, [id])
    return (
        <div>

            <Grid container spacing={2} sx={{ marginTop: '0px', '@media (max-width: 900px)': { marginTop: '0px', } }}>

                <Grid item md={12}>
                    <Grid sx={{ border: '1px solid #E4E4E4', padding: '20px', borderRadius: '10px' }}>

                        <Typography variant='h5' color='#000 !important' sx={{ marginBottom: '20px', }} >Locations</Typography>



                        <Grid container alignItems="center" spacing={3}>
                            {branchLocations.map((item, index) => (
                                <Grid item xs={6} md={3}>

                                    <a
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item?.concatenatedAddress || item?.locationname)}`}
                                        target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <Stack direction='row' spacing={1} alignItems={'center'}>

                                            <PlaceIcon sx={{ color: 'var(--green)', fontSize: '40px' }} />

                                            <Stack>
                                                <Typography sx={{ color: 'var(--primary) !important', fontSize: '16px !important', fontWeight: '600 !important' }}>
                                                    {item?.locationname}
                                                </Typography>
                                                <Typography sx={{ color: 'gray !important', fontSize: '13px !important' }}>
                                                    {item?.concatenatedAddress}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </a>

                                </Grid>
                            ))}
                        </Grid>



                    </Grid>
                </Grid>

            </Grid>
        </div>
    )
}

export default LocationCard