export const base_Url = 'https://liveapioneact.projectsideaboxdigital.io.in/api/v1/' 
// export const base_Url = 'https://testoneactdevapi.projectsideaboxdigital.io.in/api/v1/'
// export const base_Url = "http://localhost:4578/api/v1/"






// export const base_Url = 'https://oneclo.webnox.in/api/v1'

// const AWS = "https://testoneactdevapi.projectsideaboxdigital.io.in/api/v1/"