/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Chip,
  Stack,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import webimg from "../../assets/Artboard3.webp";
import mobimg from "../../assets/Artboard4.webp";
import { IoMdCloseCircleOutline } from "react-icons/io";
import "./Upsellingdialog.css";

const NewOffBanner = ({ handleClose, handleCloseOnly }) => {
  const isBelowMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const blinkingAnimation = {
    "@keyframes blink": {
      "0%": {
        borderColor: "blue",
        transform: "scale(1)", // Normal size
      },
      "50%": {
        borderColor: "transparent",
        transform: "scale(1.07)", // Bigger size
      },
      "100%": {
        borderColor: "white",
        transform: "scale(1)", // Normal size again
      },
    },
    border: "2px solid green", // Initial border color
    // animation: "blink 1.5s infinite",
  };

  // const [bgImage, setBgImage] = useState(!isBelowMd ? webimg : mobimg);
  return (
    <Box
      className="dialog-bg"
      sx={{
        color: "white",
        borderRadius: "2px",
        minHeight: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "relative",
      }}
    >
      {/* Close Button */}
      <IconButton
        sx={{
          fontSize: "40px",
          color: "#a179ad",
          background: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          padding: "3px",
          position: "absolute", // Absolute positioning
          top: "10px", // Adjust top distance
          right: "10px", // Adjust right distance
        }}
        onClick={handleCloseOnly || handleClose}
      >
        <IoMdCloseCircleOutline sx={{ fontSize: "40px" }} />
      </IconButton>

      {/* Discount Section */}
      <Stack
        spacing={2}
        sx={{
          width: "100%",
          maxWidth: "800px",
          padding: { xs: "20px 20px", sm: "40px" },
        }}
      >
        {!isBelowMd ? (
          <Typography
            variant="h3"
            sx={{
              padding: { xs: "37px 0px 0px 5px" },
              fontWeight: 600,
              color: "#a179ad !important",
              fontSize: "28px !important",
              textAlign: "center",
            }}
          >
            15% OFF Behind-the-Wheel Driving Lessons
          </Typography>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  pt: 1,
                  color: "white !important",
                  fontSize: "20px !important",
                  backgroundColor: "#a179ad",
                  width: "fit-content",
                  padding: 1,
                }}
              >
                15% OFF
              </Typography>
            </Box>

            <Typography
              sx={{
                padding: { xs: "10px 0px 0px 15px" },
                fontWeight: 700,
                color: "#a179ad !important",
                fontSize: "30px !important",
                textAlign: "center",
              }}
            >
              Behind-the-Wheel Driving Lessons
            </Typography>
          </>
        )}

        <Typography
          variant="h6"
          sx={{
            mt: { xs: 0, md: 2 },
            mb: 3,
            color: "#9ba4d3 !important",
            fontSize: "18px",
          }}
        >
          Exclusive Spring Deal awaits you!
        </Typography>

        {/* Features */}
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ marginBottom: 3 }}
        >
          <Grid item>
            <Chip
              label="Free Pick-up & Drop-Off"
              color="primary"
              sx={{ background: "#f5a1a4", boxShadow: "1px 2px 3px #621578" }}
            />
          </Grid>
          <Grid item>
            <Chip
              label="Expert Training"
              color="primary"
              sx={{ background: "#f6c377", boxShadow: "1px 2px 3px #181c17" }}
            />
          </Grid>
          <Grid item>
            <Chip
              label="Flexible Scheduling"
              color="primary"
              sx={{ background: "#f5a1a4", boxShadow: "1px 2px 3px #621578" }}
            />
          </Grid>
        </Grid>

        {/* Offer Details */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          spacing={5}
          sx={{ mb: 3 }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body1" sx={{ color: "#9ba4d3 !important" }}>
              Offer Valid
              <br />
              <strong>Apr 01 – Apr 30, 2025</strong>
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body1" sx={{ color: "#9ba4d3 !important" }}>
              Don't Miss Out <br />
              <strong>Limited Spots Available!</strong>
            </Typography>
          </Stack>
        </Stack>

        {/* Call to Action */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{
              // display: "flex",
              borderRadius: "30px",
              // border: "1px solid black",
              paddingRight: "10px",
              textAlign: "center",
              display: "flex",
              // margin: "0 auto",
              width: "fit-content",
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              sx={{
                background: "#a179ad",
                color: "white",
                fontWeight: "bold",
                boxShadow: "1px 2px 3px rgb(18, 17, 19)",
                "&:hover": { background: "#FFC700" },
                borderRadius: "20px",
                width: { xs: "100%", sm: "auto" },
              }}
            >
              Book Your Lesson Now
            </Button>
            <Typography
              variant="body2"
              sx={{
                color: "black !important",
                marginTop: { xs: "10px", sm: "0" },
              }}
            >
              <strong> Call: 770-274-4223</strong>
            </Typography>
          </Stack>
        </div>
        {/* Disclaimer */}

        {/* <Box
          sx={{
            bgcolor: "#FFD700",
            p: 2,
            borderRadius: 3,
          }}
        >
          <Typography variant="body2">
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {" "}
              Disclaimer:
            </span>{" "}
            Our phone services are temporarily unavailable due to scheduled
            maintenance. For assistance, please contact us via text at{" "}
            <span
              style={{
                fontWeight: "600",
              }}
            >
              404-590-4570.
            </span>{" "}
            We apologize for any inconvenience and appreciate your
            understanding.
          </Typography>
        </Box> */}

      </Stack>
    </Box>
  );
};

export default NewOffBanner;
