import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material'
import RlthousantBg from "../../assets/images/roadTesting/RlthousantBg.png";

import blueBox from '../../assets/images/home/footban2.jpg';

const DljlOfferGreenBanner = () => {

    const styles = {
        greenBox: {
            background: `url(${RlthousantBg}) no-repeat top center`, backgroundSize: '100% 100%', padding: '40px 80px', marginTop: '40px', textAlign: 'center',
            '@media (max-width: 900px)': {
                padding: '30px',
                marginTop: '20px'
            },
        },
        offersh2: {
            fontSize: '18px !important',
            fontWeight: '400 !important',
            lineHeight: '1.6',
            color: '#fff !important',
            marginBottom: '14px !important',
        },
        greatOffersBut: {
            background: "var(--yellow)",
            border: "1px solid var(--yellow)",
            fontSize: "20px",
            fontWeight: "600",
            padding: '4px 20px',
            transition: '1s',
            color: '#000',
            borderRadius: '0px',
            "&:hover": {
                background: "#fff",
                color: "#fff",
                border: "1px solid var(--green)",
                boxShadow: "inset 300px 0px 0px var(--green)",
                transition: '1s'
            }
        },
        offersa: {
            cursor: 'pointer',
            color: 'var(--yellow) !important'
        },
        blueBox: {
            marginTop: '30px',
            background: `url(${blueBox}) no-repeat top center`,
            backgroundSize: 'cover', padding: '30px',
            borderRadius: '10px',
        },
        blueBoxText: {
            color: '#fff !important',
            background: '#00000054',
            padding: '10px 10px',
            borderRadius: '10px',
            textAlign: 'center',
            fontWeight: '400 !important',
        }

    }


    const navigate = useNavigate()
    return (
        <>

        <Container sx={{ pb:"60px",}}>


            <Grid sx={styles.greenBox}>
                <Grid sx={{}}>

                    <Typography variant='h4' className="spec_offer">***Special Offers***</Typography>
                    <br/>
                    <Typography variant="h2" sx={styles.offersh2}>Buy 6 or more hours of Behind the wheel lessons, get 30-hour Drivers Education for only $95</Typography>
                    {/* <Typography variant="h2" sx={styles.offersh2}>Buy 12 or more hours of behind the wheel lessons, get defensive driving absolutely FREE!!!</Typography>
                    <Typography variant="h2" sx={styles.offersh2}>Add Defensive driving class to your purchase of 2 to 10 hours of behind the wheel lessons at a discounted Price, avail a minimum of 10% car insurance discount that is valid for 3 YEARS!</Typography>
                    <br/>
                    <Typography component="a" href='https://www.1statlantaduischool.com/insurance-reduction/' sx={{padding:'4px 10px',background: '#000000', borderRadius: '4px',}}>Click here to learn more about the defensive driving class!</Typography> */}

                    {/* <Typography component='a' sx={styles.offersa}>Click here to learn more about the defensive driving class!</Typography> */}
                    {/* <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px 0px 0px ', width: 'unset' }}>
                        <Grid>
                            <Button variant="outlined" className=''
                                sx={styles.greatOffersBut}
                            >
                                With GREAT OFFERS

                            </Button>
                        </Grid>
                        <Grid>
                            <Button variant="outlined" className='greenButAni' onClick={() => navigate('/schedule-driving-lessons-2/2')}>
                                GET NOW
                            </Button>
                        </Grid>

                    </Grid> */}

                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',   }}>
                        <Grid>

                            {/* <Button variant="outlined" className=''
                                sx={{
                                    background: "var(--yellow)",
                                    border: "1px solid var(--yellow)",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    padding: '4px 20px',
                                    transition: '1s',
                                    color: '#000',
                                    borderRadius: '0px',

                                    "&:hover": {
                                        background: "#fff",
                                        color: "#fff",
                                        border: "1px solid var(--green)",
                                        boxShadow: "inset 300px 0px 0px var(--green)",
                                        transition: '1s'
                                    },
                                    marginTop:'40px'
                                }}
                            >
                                 GREAT OFFERS

                            </Button> */}

                        </Grid>
                        <Grid>
                            <Button variant="outlined" className='greenButAni' onClick={() =>navigate('/schedule-driving-lessons-2',{
                                      state:{number:2}
                                    })}>
                                GET AS A PACKAGE
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* <Grid sx={styles.blueBox}>
                <Typography variant='h6' sx={styles.blueBoxText}>Special -- 30-hour drivers education class only $95 with any package!
                    Behind-the-wheel training is in-person, of course. However, 30-hour drivers education class is offered LIVE via Zoom. All classes are approved by any County in State of Georgia.</Typography>
            </Grid> */}

        </Container>


        </>
    )
}

export default DljlOfferGreenBanner